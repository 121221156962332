'use client';
import { Image } from '@bw/elements';
import { InsuranceIconsAuto, InsuranceIconsBusiness, InsuranceIconsCondo, InsuranceIconsFlood, InsuranceIconsHome, InsuranceIconsLife, InsuranceIconsMotorcycle, InsuranceIconsOther, InsuranceIconsPersonal, InsuranceIconsRental, InsuranceIconsRv, InsuranceIconsToys, InsuranceIconsUmbrella } from '@bw/elements/icons';
import { childrenPlayingImg, familyWithDogImg, grandpaWithCarImg } from '../../stock-images';
import styles from './IconArticleBanner.module.scss';

export function IconArticleBanner() {
	return (
		<section className={styles.iconArticleBannerSection}>
			<div className="container container--max">
				<div className={styles['iconArticleBanner']} data-aos="fade-up">
					<figure data-aos="fade-up">						
						<Image
							src={familyWithDogImg}
							width={1000}
							height={1000}
							alt=""
						/>
					</figure>
					<div className={styles.description} data-aos="fade-up">
						<div className={styles.iconContainer} data-aos="fade-up">
							<InsuranceIconsHome className="svg" />
							<InsuranceIconsFlood className="svg" />
							<InsuranceIconsCondo className="svg" />
							<InsuranceIconsRental className="svg" />
							<InsuranceIconsPersonal className="svg" />
						</div>
						<article data-aos="fade-up">
							<h5>Property Insurance</h5>
							<p>
								When it comes to insuring your property, you want a local agent
								who knows your neighborhood and can provide the most policy
								options. That’s us. We’re able to give you the advice you need
								and the most choice when it comes to covering your largest
								investment.
							</p>
						</article>
					</div>
				</div>
				<div className={styles['iconArticleBanner']} data-aos="fade-up">
					<figure data-aos="fade-up">
						<Image
							src={grandpaWithCarImg}
							width={1000}
							height={1000}
							alt={''}
						/>
					</figure>
					<div className={styles.description}>
						<div className={styles.iconContainer}>
							<InsuranceIconsAuto className="svg" />
							<InsuranceIconsMotorcycle className="svg" />
							<InsuranceIconsRv className="svg" />
							<InsuranceIconsToys className="svg" />
						</div>
						<article data-aos="fade-up">
							<h5>Vehicle Insurance</h5>
							<p>
								Properly insuring the way you get from A to B (or Z) is
								important. Many insurance companies will offer convenient or
								low-cost solutions, but we’re the only ones who can shop the
								market to help ensure that you get the policy you need at the
								price you want.
							</p>
						</article>
					</div>
				</div>
				<div className={styles['iconArticleBanner']} data-aos="fade-up">
					<figure data-aos="fade-up">
						<Image
							src={childrenPlayingImg}
							width={1000}
							height={1000}
							alt=""
						/>
					</figure>
					<div className={styles.description}>
						<div className={styles.iconContainer}>
							<InsuranceIconsLife className="svg" />
							<InsuranceIconsUmbrella className="svg" />
							<InsuranceIconsOther className="svg" />
						</div>
						<article data-aos="fade-up">
							<h5>Other Insurance</h5>
							<p>
              We can help you with more than just home and auto policies. We specialize
              in finding the right coverage to protect you from whatever life can throw
              your way. Our expertise and access to more than twice as many carriers
              can give you the peace-of-mind that only comes with knowing you’re
              properly covered.
							</p>
						</article>
					</div>
				</div>
				<div className={styles['iconArticleBanner']} data-aos="fade-up">
					<figure data-aos="fade-up">
						<Image
							src={familyWithDogImg}
							width={1000}
							height={1000}
							alt=""
						/>
					</figure>
					<div className={styles.description} data-aos="fade-up">
						<div className={styles.iconContainer} data-aos="fade-up">
							<InsuranceIconsBusiness className="svg" />
						</div>
						<article data-aos="fade-up">
							<h5>Business Insurance</h5>
							<p>
              Whether you’re running a multi million dollar company or a sole
              proprietorship, having the right insurance will let you focus on
              what you do best. Our team is made up of insurance experts who take
              the time to build a relationship with you to help you identify the
              potential risks that are unique to your business. Armed with that
              information, we shop the most carriers to find the policies that
              will do the heavy lifting.
							</p>
						</article>
					</div>
				</div>
			</div>
		</section>
	);
}

export default IconArticleBanner;
