'use client';
import AOS from 'aos';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from './AgencyHomeTextOnlySlider.module.scss';

export function AgencyHomeTextOnlySlider() {
	const [activeSlide, setActiveSlide] = useState(0);
	useEffect(() => {
		AOS.init();
	}, []);

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		arrows: false,
	};

	const handleSetActiveSlide = (index: number) => {
		setActiveSlide(index);
	};

	return (
		<section className={styles.onlyTextContainerSection}>
			<div className="container container--max">
				<div className={styles.onlyTextContainer} data-aos="fade-up">
					<Slider afterChange={handleSetActiveSlide} {...settings}>
						<p aria-hidden={activeSlide === 0 ? false : true}>
							Your future-you will thank you.
						</p>

						<p aria-hidden={activeSlide === 1 ? false : true}>
							Choice is always a good decision.
						</p>

						<p aria-hidden={activeSlide === 2 ? false : true}>
							Insurance your way.
						</p>

						<p aria-hidden={activeSlide === 3 ? false : true}>
							We&apos;re bullish about your future.
						</p>

						<p aria-hidden={activeSlide === 4 ? false : true}>
							More carriers. More choice.
						</p>

						<p aria-hidden={activeSlide === 5 ? false : true}>
							We don&apos;t just know insurance. We know your community.
						</p>

						<p aria-hidden={activeSlide === 6 ? false : true}>
							Get insurance the right way. Not the cross-your-fingers way.
						</p>
					</Slider>
				</div>
			</div>
		</section>
	);
}
