'use client';

import Script from "next/script";
import { useEffect, useRef } from "react";
import styles from './CareersListing.module.scss';

let careerDomNode: Element | null | undefined = null;

export const CareersListing = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const containerDiv = ref.current;
    if (careerDomNode && containerDiv) {
      containerDiv.appendChild(careerDomNode);
    }

    return () => {
      careerDomNode = containerDiv?.querySelector('.clearcompany-include');
      if (careerDomNode) {
        careerDomNode.remove();
      }
    };
  }, []);

  return <>
    <div ref={ref} className={styles.container}>
      <div className='clearcompany-include'></div>
    </div>
    <Script src='https://careers-content.clearcompany.com/js/v1/career-site.js?siteId=5bc7ad48-f6fb-d8e9-a799-37d85d28682d&contentSelector=.clearcompany-include' />
  </>;
};
