import { Navigator } from '@bw/elements/icons';
import Link from 'next/link';
import processWebsiteLink from '../../helpers/process-websites';
import Button from '../utility/button/button';
import styles from './SearchItem.module.scss';

export interface CompanyData {
	id: string;
	companyName: string;
	carrierType?: string;
	phoneNumber?: string;
	claimPhoneNumber?: string;
	paymentPhoneNumber?: string;
	website?: string;
	websitePaymentLink?: string;
}

export interface SearchItemProps {
	company: CompanyData | null;
}

export function SearchItem({ company }: SearchItemProps) {
	if (!company) {
		// Handle the case where company data is null
		return null;
	}

	return (
		<div className={styles['search__item']} data-aos="fade-up">
			{company.website ? (
				<Link
					className="title__name"
					href={processWebsiteLink(company.website) || '#'}
					target={processWebsiteLink(company.website) ? '_blank' : ''}
					aria-label={
						processWebsiteLink(company.website)
							? `${company.companyName} Website (opens in a new tab)`
							: ''
					}
					title={`${company.companyName} Website`}
				>
					<h2>{company.companyName}</h2>
					<Navigator className="svg" aria-hidden width={24} height={24} />
				</Link>
			) : (
				<span className="title__name">
					<h2>{company.companyName}</h2>
				</span>
			)}

			<ul>
				{company.phoneNumber && (
					<li>
						<p>Call</p>
						<a href={`tel:${company.phoneNumber}`} title={company.phoneNumber}>
							{company.phoneNumber}
						</a>
					</li>
				)}
				{company.claimPhoneNumber && (
					<li>
						<p>Report a claim by phone</p>
						<a
							href={`tel:${company.claimPhoneNumber}`}
							title={company.claimPhoneNumber}
						>
							{company.claimPhoneNumber}
						</a>
					</li>
				)}
				{company.paymentPhoneNumber && (
					<li>
						<p>Make a payment by phone</p>
						<a
							href={`tel:${company.paymentPhoneNumber}`}
							title={company.paymentPhoneNumber}
						>
							{company.paymentPhoneNumber}
						</a>
					</li>
				)}
			</ul>
			<div className="search__item-buttom">
				{processWebsiteLink(company.websitePaymentLink) && (
					<Button
						title="Make a payment online"
						href={processWebsiteLink(company.websitePaymentLink) || '#'}
						textColor="deepBlue"
						backgroundColor="white"
						borderColor="periwinkleGrey"
						type="button"
						openInNewTab={true}
					/>
				)}
			</div>
		</div>
	);
}

export default SearchItem;
