import { slugify } from "@bw/core";
import { Agency } from "./types";

// TODO(khaney): add marketingName back to the end of the slug
type AgencySlugInfo = Pick<
	Agency,
	'stateAbbreviation' | 'city' | 'storeNumber' | 'marketingName' | 'alias'
>;
export const getAgencySEOSlug = (agency: AgencySlugInfo): string => {
	// const marketingName = slugify(agency.marketingName || agency.name)
	const alias = slugify(agency.alias || agency.city);
	return `/agencies/${agency.stateAbbreviation}/${alias}/${agency.storeNumber}/`;
};
