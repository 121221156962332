import { OfficeHours as IOfficeHours } from '@bw/external-apis/shared';
import { Fragment } from 'react';
import { getOfficeHoursDisplay } from './office-hours';

interface OfficeHoursProps {
	officeHours: IOfficeHours | null;
	header?: JSX.Element;
	Container?: keyof JSX.IntrinsicElements;
}

// TODO(khaney): factor out of agencies
export const OfficeHours = ({
	officeHours,
	Container = 'h5',
	header = <p>Office Hours</p>,
}: OfficeHoursProps) => {
	if (!officeHours) return null;
	return (
		<>
			{header}
			<Container>
				{getOfficeHoursDisplay(officeHours).map((dailySchedule, index) => (
					<Fragment key={index}>
						{dailySchedule}
						<br />
					</Fragment>
				))}
			</Container>
		</>
	);
};
