'use client';
import { AgencyDetails } from '@bw/external-apis/shared';
import { MapComponent } from '../../../map/MapComponent';
import { AgencyAddress } from '../../agency-address/AgencyAddress';
import { OfficeHours } from '../../office-hours/OfficeHours';
import styles from './TeamHalfTextHalfMap.module.scss';

export interface TeamHalfTextHalfMapProps {
	agencyDetails: AgencyDetails;
	mapsApiKey?: string;
}

export function TeamHalfTextHalfMap({	agencyDetails, mapsApiKey }: TeamHalfTextHalfMapProps) {
	const hideAddress =
		agencyDetails.agency.corporateHideAddress ||
		agencyDetails.agency.agencyTypeId === 2;
	if (hideAddress) {
		return <></>;
	}
	return (
		<section className={styles.AgencyteamSection}>
			<div className="container container--max">
				<div className={styles.insuranceHalfTextHalfMap} data-aos="fade-up">
					<article className={styles.textSection} data-aos="fade-up">
						<div>
							<h3 data-aos="fade-up">
								Visit us to learn more about{' '}
								{agencyDetails.intro.city || agencyDetails.agency.city}{' '}
								Insurance
							</h3>
						</div>
						<h4 data-aos="fade-up">
							{/* <h4 className={styles.headingDescription} data-aos="fade-up"> */}
							We&apos;re eager to help you find the right insurance online or
							in-person. Visit our office to meet your new insurance team.
						</h4>
						<p data-aos="fade-up">Our Office</p>
						<h5 className={styles.address} data-aos="fade-up">
							<AgencyAddress agency={agencyDetails.agency} />
						</h5>
						<OfficeHours officeHours={agencyDetails.officeHours} />
					</article>
					<div className={styles.mapSection}>
						<MapComponent agencyDetails={agencyDetails.agency} apiKey={mapsApiKey} />
					</div>
				</div>
			</div>
		</section>
	);
}
