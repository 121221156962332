'use client';
import { useSuspenseQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { Image } from '@bw/elements';
import { PlayButton } from '@bw/elements/icons';
import { useState } from 'react';
import { GET_VIDEO_TESTIMONIALS } from '../../queries';
import styles from './VideoListing.module.scss';

export interface VideoListingProps {
	name: string;
}

interface VideoListingData {
	listingModelCollection: {
		items: {
			description: string;
			heading: string;
			name: string;
			personCardCollection: {
				items: {
					agencyName: string;
					location: string;
					name: string;
					url: string;
				}[];
			};
		}[];
	};
}

export function VideoListing(props: VideoListingProps) {
	const { data } = useSuspenseQuery<VideoListingData>(GET_VIDEO_TESTIMONIALS, {
		variables: {
			component: props.name as string,
		},
	});

	const componentData = data.listingModelCollection.items[0];
	const videoData = componentData.personCardCollection.items;

	const regex =
		/(?:embed\/|v=|vi\/|youtu\.be\/|\/v\/|\/e\/|watch\?v=)([\w\-_]{11})/;

	// Extract video IDs from each URL in videoData
	const videoUrls = videoData.map((item) => {
		const match = item.url.match(regex);
		const videoId = match ? match[1] : null;
		return videoId
			? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
			: null;
	});

	const [visibleVideos, setVisibleVideos] = useState(videoData.map(() => true));

	const handleImageClick = (index) => {
		setVisibleVideos((prev) =>
			prev.map((value, i) => (i === index ? false : value))
		);
	};

	return (
		<>
			<section className={styles['leadership__hero']}>
				<div className="container container--max">
					<div className="container">
						<div className="hero__content">
							<h2 data-aos="fade-up" data-aos-delay="100">
								{componentData.heading}
							</h2>
							<p data-aos="fade-up" data-aos-delay="300">
								{componentData.description}
							</p>
						</div>
					</div>
				</div>
			</section>
			<section
				className={styles['leaders__listing']}
				data-aos="fade-up"
				data-aos-delay="360"
			>
				<div className="container container--max">
					<ul>
						{videoData.map((video, index) => (
							<li data-aos="fade-up" key={index}>
								<div className={styles['leaders']}>
									<figure
										className="aspect-box is__video"
										style={
											{ '--aspect-ratio': '225/400' } as React.CSSProperties
										}
									>
										<div
											onClick={() => handleImageClick(index)}
											style={{
												display: visibleVideos[index] ? 'block' : 'none',
											}}
											className="image_logo_wrapper"
											tabIndex={0}
											onKeyDown={(e) => {
												// Handle Enter key press
												if (e.key === 'Enter') {
													handleImageClick(index);
												}
											}}
										>
											<div
												style={{
													transition: 'opacity 0.5s ease-in-out',
												}}
											>
												<span className="play__icon">
													<button className="play_button" aria-label="Play">
														<PlayButton width={55} height={55} />
													</button>
												</span>
												<Image
													className="poster__img"
													src={videoUrls[index] ?? ''}
													alt="youtube thumbnail"
													width={450}
													height={450}
													key={index}
												/>
											</div>
										</div>
										{!visibleVideos[index] && (
											<iframe
												width="560"
												height="315"
												src={`${video.url}&autoplay=1`}
												title="YouTube video player"
												frameBorder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
												allowFullScreen
											></iframe>
										)}
									</figure>

									<article>
										<h2 data-aos="fade-up">{video.agencyName}</h2>
										<p data-aos="fade-up">{video.location}</p>
									</article>
								</div>
							</li>
						))}
					</ul>
				</div>
				<hr />
			</section>
		</>
	);
}

export default VideoListing;
