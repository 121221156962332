export function formatTime(seconds) {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);

	let result = '';

	if (hours > 0) {
		result += `${hours}h `;
	}

	if (minutes > 0 || hours === 0) {
		result += `${minutes}min`;
	}

	return result;
}
