'use client';
import { AgencyDetails } from '@bw/external-apis/shared';
import { MapComponent } from '../../map/MapComponent';
import { AgencyAddress } from '../agency-address/AgencyAddress';
import { OfficeHours } from '../office-hours/OfficeHours';
import styles from './AgencyHalfTextHalfMap.module.scss';

export interface AgencyHalfTextHalfMapProps {
	agencyDetails: AgencyDetails;
	mapsApiKey?: string;
}

export function AgencyHalfTextHalfMap({ agencyDetails, mapsApiKey }: AgencyHalfTextHalfMapProps) {
  const hideAddress = agencyDetails.agency.corporateHideAddress || agencyDetails.agency.agencyTypeId === 2
  if (hideAddress) {
    return <></>;
  }
	return (
		<section className={styles.agencyHalfTextHalfMapSection}>
			<div className="container container--max">
				<div className={styles.agencyHalfTextHalfMap}>
					<article className={styles.textSection}>
						<div>
							<h3 data-aos="fade-up">
								Visit your local <b>{agencyDetails.intro.city}</b> Office
							</h3>
						</div>
						<h4 data-aos="fade-up">
							Give us a call or come on by the office to meet your new insurance
							team. We’re eager to help you find the right insurance over the
							phone or in-person.
						</h4>
						<div>
							<AgencyAddress {...agencyDetails} />
						</div>
						<OfficeHours officeHours={agencyDetails.officeHours} />
					</article>
					<div className={styles.mapSection} data-aos="fade-up">
						<MapComponent agencyDetails={agencyDetails.agency} apiKey={mapsApiKey} />
					</div>
				</div>
			</div>
		</section>
	);
}
