'use client';
import { Image } from '@bw/elements';
import { heroImg } from '../../../../stock-images';
import { useAppContext } from '../../../form-stepper/StepperProvider';
import Button from '../../../utility/button/button';
import styles from './support-hero-banner.module.scss';

type AnnouncementHeightProps = Record<string, string>;

export const SupportHeroBanner = () => {
	const { announcementBannerHeight } = useAppContext();
	const announcementHeight: AnnouncementHeightProps = {
		'--announcement-banner-height': `${announcementBannerHeight}px`,
	};
	return (
		<div
			className={`${styles.heroBanner} ${styles['heroBanner--imagePosition']} `}
			data-aos="fade-up"
			style={announcementHeight}
		>
			<figure className={styles.imageContent} data-aos="fade-up">
				<Image src={heroImg} alt="Hero" width={1000} height={1000} />
			</figure>
			<article className={`${styles.textContent}`}>
				<span className="headingTagline" data-aos="fade-up">
					Need Help?
				</span>

				<h1 data-aos="fade-up">
					Serving our customers is at the heart of what we do.
				</h1>

				<p data-aos="fade-up">
					For questions on an existing policy, please contact our Customer
					Service Department.
				</p>

				<Button
					title="Customer Service"
					href="/customer-service"
					textColor="deepBlue"
					backgroundColor="brightYellow"
					borderColor="none"
					type="button"
				/>
			</article>
		</div>
	);
};
