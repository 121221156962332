'use client';
import { CarrierListItem } from '@bw/external-apis';
import { useState } from 'react';
import { MyContext } from '../../helpers/context';
import CompaniesSearchBanner from '../companies-search-banner/CompaniesSearchBanner';
import SearchResult from '../search-result/SearchResult';
import styles from './CompaniesList.module.scss';
import { companiesPageData } from './company-list-types';

interface CompaniesSearchData {
	companiesWeRepresentCollection: { items: companiesPageData[] };
	carrierList: CarrierListItem[] | null;
}

export const CompaniesSearch = ({
	companiesWeRepresentCollection,
	carrierList,
}: CompaniesSearchData) => {
	const [searchQuery, setSearchQuery] = useState('');
	const [activeAlphabet, setActiveAlphabet] = useState('');
	const contextValue = {
		searchQuery,
		setSearchQuery,
		activeAlphabet,
		setActiveAlphabet,
	};
	const companiesPageData = companiesWeRepresentCollection.items[0];
	return (
		<div className={styles['companiesList']}>
			<MyContext.Provider value={contextValue}>
				<CompaniesSearchBanner bannerData={companiesPageData} />
				{carrierList && <SearchResult carrierList={carrierList} />}
			</MyContext.Provider>
		</div>
	);
};
