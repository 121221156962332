import { Image } from '@bw/elements';
import { Pause, Play } from '@bw/elements/icons';
import { PodcastItem } from '@bw/external-apis/shared';
import { useEffect, useRef, useState } from 'react';
import { formatDate } from '../../helpers/date-convert';
import { useAppContext } from '../form-stepper/StepperProvider';
import styles from './PodcastPlayer.module.scss';

interface PodcastPlayerProps {
	podcastData: PodcastItem;
}

const PodcastPlayer: React.FC<PodcastPlayerProps> = ({ podcastData }) => {
	const { title, published_at, artwork_url, audio_url } = podcastData;
	const [audioPlay, setAudioPlay] = useState(false);
	const { podcastPopupDisplay, setPodcastPopupDisplay } = useAppContext();

	const audioRef = useRef<HTMLAudioElement | null>(null);

	const handleTogglePlay = () => {
		if (audioRef?.current?.paused) {
			setAudioPlay(true);
			audioRef?.current?.play();
		} else {
			setAudioPlay(false);
			audioRef?.current?.pause();
		}
	};

	useEffect(() => {
		handleTogglePlay();
	}, [podcastData]);

	useEffect(() => {
		const audioElement = audioRef.current;

		if (audioElement) {
			const handlePlay = () => {
				setAudioPlay(false);
			};

			const handlePause = () => {
				setAudioPlay(true);
			};

			// Add event listeners
			audioElement.addEventListener('play', handlePlay);
			audioElement.addEventListener('pause', handlePause);

			// Clean up event listeners on component unmount
			return () => {
				audioElement.removeEventListener('play', handlePlay);
				audioElement.removeEventListener('pause', handlePause);
			};
		}
	}, []);

	const handleClosePlayer = () => {
		setPodcastPopupDisplay(false);
	};

	if (!podcastPopupDisplay) {
		return null;
	}

	return audio_url ? (
		<div className={styles['PodcastPlayer']}>
			<div className="PodcastPlayer__wrapper">
				<figure>
					<Image alt="image" src={artwork_url} width={100} height={100} />
				</figure>
				<div className="player__title">
					<h2>{title}</h2>
					<p>{formatDate(published_at)}</p>
				</div>

				<button className="player__play-pause" onClick={handleTogglePlay}>
					{audioPlay ? (
						<Play width={100} height={100} />
					) : (
						<Pause width={100} height={100} />
					)}
				</button>
				<button className="close-btn" onClick={handleClosePlayer}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
					>
						<path
							d="M12 4L4 12M4 4L12 12"
							stroke="black"
							strokeWidth="1.33333"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>
			<div className="audio__player">
				<audio
					ref={audioRef}
					id="audio-player"
					src={audio_url}
					autoPlay
					controls
				/>
			</div>
		</div>
	) : (
		<></>
	);
};

export default PodcastPlayer;
