'use client';
import { Image } from '@bw/elements';
import { Loader } from '@bw/elements/icons';
import { PodcastItem } from '@bw/external-apis/shared';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { useEffect, useState } from 'react';
import { useAppContext } from '../form-stepper/StepperProvider';
import PodcastPlayer from '../podcast-player/PodcastPlayer';
import Button from '../utility/button/button';
import FunctionalityButton from '../utility/functionality-button/functionalityButton';
import styles from './PodcastBannerRichText.module.scss';
import { PodcastBannerData } from './podcast-banner-type';

type PodcastBannerRichTextProps = {
	banner: PodcastBannerData;
	podcastItems: PodcastItem[];
};

export function PodcastView({ banner, podcastItems }: PodcastBannerRichTextProps) {
	const [podcastData, setPodcastData] = useState<PodcastItem>(podcastItems[0]);
	const [playPodcast, setPlayPodcast] = useState<boolean>(false);
	const { podcastPopupDisplay, setPodcastPopupDisplay } = useAppContext();
	const [loading, setLoading] = useState(true);

	const optionHeading = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => {
				return <h2 data-aos="fade-up">{children}</h2>;
			},
		},
	};
	const options_method = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => {
				return <p data-aos="fade-up">{children}</p>;
			},
		},
	};

	const getPodcastData = () => {
		try {
			setPodcastData(podcastItems[0]);
			setLoading(false); // Set loading to false once data is received
		} catch (error) {
			console.error(error);
			setLoading(false); // Set loading to false in case of an error
		}
	};

	useEffect(() => {
		getPodcastData();
	}, []);

	const handlePlayLatestEpisode = () => {
		setPlayPodcast(true);
		setPodcastPopupDisplay(true);
	};

	const bannerImage = banner.image.image;

	return (
		<>
			<div className={styles['PodcastBanner']}>
				{loading ? (
					// Display a loader while data is being fetched
					<div className="is__pageloader">
						<figure className={styles.loader} data-aos="fade-up">
							<Loader width={100} height={100} />
						</figure>
					</div>
				) : (
					<div className="container">
						<div className="PodcastBanner__row" data-aos="fade-up">
							<div className="PodcastBanner__img">
								<figure data-aos="fade-up">
									<Image
										alt={bannerImage.title}
										src={bannerImage.url}
										height={bannerImage.height}
										width={bannerImage.width}
									/>
								</figure>
							</div>
							<div
								className="PodcastBanner__content"
								data-aos="fade-up"
								data-aos-delay="200"
							>
								<span className="headingTagline" data-aos="fade-up">
									{banner.headingTagline}
								</span>
								{banner.headingRichText.json &&
									documentToReactComponents(
										banner.headingRichText.json,
										optionHeading
									)}

								{banner.description.json &&
									documentToReactComponents(
										banner.description.json,
										options_method
									)}

								<div className="PodcastBanner__buttons">
									<FunctionalityButton
										title="Play the latest episode"
										textColor="deepBlue"
										backgroundColor="brightYellow"
										borderColor="brightYellow"
										containsPlayIcon={true}
										onClick={handlePlayLatestEpisode}
									/>

									{
										banner.buttonsCollection?.items?.map((button, index) => (
											<Button
												backgroundColor={button.backgroundColour}
												textColor={button.textColour}
												href={button.buttonUrl}
												borderColor={button.borderColour}
												title={button.title}
												key={index}
												playIcon={button.containsPlayIcon}
												openInNewTab={button.openInNewTab ?? false}
											/>
										))}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			{playPodcast && <PodcastPlayer podcastData={podcastData} />}
		</>
	);
}
