'use client';
import { Image } from '@bw/elements';
import { AgencyInsuranceType } from '@bw/external-apis/shared';
import {
    Bike_in_road,
    CoupleMovingIn__3_,
    CoupleMoving_inLogo,
    Cruise,
    DogandHouseInLogo,
    FamilyOnPorch_with_Logo,
    NewCarOwner_inLogo,
    Property_1_SmallModernHouse_inLogo,
    Property_1_Studio_Woman_inLogo,
    RVinBeach,
    condoHero,
} from '../../../../stock-images';
import { useAppContext } from '../../../form-stepper/StepperProvider';
import { ShortForm } from '../../short-form/ShortForm';
import styles from './InsuranceImageColTextForm.module.scss';

export interface InsuranceImageColTextFormProps {
	insuranceType: AgencyInsuranceType;
	serviceCity: string;
	marketingName: string;
}
type AnnouncementHeightProps = Record<string, string>;

export function InsuranceImageColTextForm({
	insuranceType,
	serviceCity,
	marketingName,
}: InsuranceImageColTextFormProps) {
	const { insuranceTypeName } = insuranceType;
	let paragraph = '';
	let image = Property_1_SmallModernHouse_inLogo;
	switch (insuranceType.insuranceTypeId) {
		case 1:
			image = FamilyOnPorch_with_Logo;
			break;
		case 2:
			image = FamilyOnPorch_with_Logo;
			paragraph = `Find flood insurance in ${serviceCity}, even if you're in a flood zone, with the help of an expert who knows what you need.
			`;
			break;
		case 3:
			image = condoHero;
			break;
		case 4:
			image = CoupleMovingIn__3_;
			break;
		case 5:
			image = CoupleMoving_inLogo;
			paragraph = `Let an expert help you find the right ${insuranceTypeName} insurance coverage in ${serviceCity}`;
			break;
		case 6:
			image = NewCarOwner_inLogo;
			paragraph = `Let a local Brightway insurance agent help find the right auto insurance coverage in ${serviceCity}.`;
			break;
		case 7:
			image = Bike_in_road;
			paragraph = `Let a local expert help you find${' '}
				${insuranceTypeName.toLocaleLowerCase()} insurance in ${serviceCity}${' '}
				that fits you.`;
			break;
		case 8:
			image = RVinBeach;
			paragraph = `Let a local expert help you find${' '}
			${insuranceTypeName} insurance in ${serviceCity}${' '}
			that fits you.`;
			break;
		case 9:
			image = Cruise;
			break;
		case 10:
			image = Property_1_Studio_Woman_inLogo;
			break;
		case 11:
			image = FamilyOnPorch_with_Logo;
			break;

		case 12:
			image = DogandHouseInLogo;
			paragraph = `Let us help you prepare for life's unexpected challenges with umbrella insurance in ${serviceCity}.`;
			break;
		case 15:
			image = DogandHouseInLogo;
			paragraph = `We love pets too. Let a local agent help find the right pet insurance in ${serviceCity}.`;
			break;

		default:
			paragraph = `Let a local expert help you find${' '}
				${insuranceTypeName.toLocaleLowerCase()} insurance in ${serviceCity}${' '}
				that fits you.`;
			break;
	}
	const { announcementBannerHeight } = useAppContext();
	const announcementHeight: AnnouncementHeightProps = {
		'--announcement-banner-height': `${announcementBannerHeight}px`,
	};
	return (
		<div
			className={`${styles.insuranceImageColTextForm} ${styles['imageColTextForm--imageSmallDesign']}`}
			style={announcementHeight}
		>
			<article className={styles.textSection}>
				<span className="headingTagline" data-aos="fade-up">
					{marketingName}
				</span>

				<h1 data-aos="fade-up">
					Get{' '}
					{insuranceType.insuranceTypeId === 1
						? 'Homeowners'
						: insuranceTypeName}{' '}
					Insurance in {serviceCity}
				</h1>
				<p className={styles.description} data-aos="fade-up">
					{paragraph}
				</p>
				<span className={styles.formTagline} data-aos="fade-up">
					Let&apos;s talk.
				</span>
				<ShortForm insuranceTypeId={insuranceType.insuranceTypeId} />
			</article>
			<figure className={styles.imageSection} data-aos="fade-up">
				<Image
					alt="agency home hero image"
					src={image}
					width={1000}
					height={1000}
				/>
			</figure>
		</div>
	);
}
