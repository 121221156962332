'use client';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

export function Select({
	id,
	name,
	options,
	paramName,
}: {
	id: string;
	name: string;
	options: { display: string; value: string | undefined }[];
	paramName: string;
}): JSX.Element {
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const { replace } = useRouter();

	// Function to get the value of a specific parameter from searchParams
	const getParamValue = (param: string): string | null => {
		const params = new URLSearchParams(searchParams);
		return params.get(param);
	};

	// Use the getParamValue function to get the value of 'articleTag'
	const articleTagValue = getParamValue('articleTag');

	const onItemSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selection = e.target.value;
		const params = new URLSearchParams(searchParams);
		if (selection) {
			params.set(paramName, selection);
		} else {
			params.delete(paramName);
		}
		params.delete('page');
		replace(`${pathname}?${params.toString()}`);
	};

	return (
		<>
			<select id={id} name={name} onChange={onItemSelected} tabIndex={0} defaultValue={articleTagValue ?? undefined}>
				{options.map(({ display, value }) => (
					<option
						value={value}
						key={value}
					>
						{display}
					</option>
				))}
			</select>
		</>
	);
}
