'use client';
import { useSuspenseQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { Image } from '@bw/elements';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import React from 'react';
import { GET_HERO_BANNER_ITEMS } from '../../queries';
import { ThemeColor } from '../../theme';
import { CalendlyButton } from '../CalendlyButton/CalendlyButton';
import { useAppContext } from '../form-stepper/StepperProvider';
import StepperButton from '../stepper-button/StepperButton';
import Button from '../utility/button/button';
import styles from './hero-banner.module.scss';

type AnnouncementHeightProps = Record<string, string>;

export interface HeroBannerProps {
	bannerButtonImageContainerCollection: {
		items: BannerButtonImageContainer[];
	};
}

interface BannerButtonImageContainer {
	name: string;
	isFirstComponent: boolean;
	containsStartAQuoteButton: boolean | null;
	containsBookACallButton: boolean | null;
	backgroundColour: string;
	textImageSection: TextImageSection;
	buttonCollection: BannerButtonImageContainerButtonCollection;
}

interface TextImageSection {
	heading: TextImageSectionHeading;
	headingTagline?: string;
	richTextDescription: any;
	sectionImage: Asset;
	imagePosition: string;
	imageBackgroundPosition?: string;
	equalWidth?: boolean;
	subtitle?: string;
}

interface TextImageSectionHeading {
	json: any;
}

interface Asset {
	url: string;
	title: string;
	width: number;
	height: number;
}

interface BannerButtonImageContainerButtonCollection {
	items: Button[];
}

interface Button {
	title: string;
	buttonUrl: string;
	textColour: ThemeColor;
	containsPlayIcon?: boolean;
	backgroundColour: ThemeColor;
	borderColour: ThemeColor;
	type: string;
	openInNewTab?: boolean;
}

export function HeroBanner(props: { name: string }) {
	const { data } = useSuspenseQuery<HeroBannerProps>(GET_HERO_BANNER_ITEMS, {
		variables: {
			component: props.name,
		},
	});
	const { announcementBannerHeight } = useAppContext();

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => {
				return <h1 data-aos="fade-up">{children}</h1>;
			},
		},
	};

	const options2 = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => {
				return <p data-aos="fade-up">{children}</p>;
			},
			[INLINES.HYPERLINK]: (node: any) => {
				return (
					<a href={node.data.uri} title={node.content[0].value} target="_blank">
						{node.content[0].value}
					</a>
				);
			},
			[BLOCKS.LIST_ITEM]: (node: any, children: React.ReactNode) => {
				return (
					<li className={styles.address} data-aos="fade-up">
						{children}
					</li>
				);
			},
		},
	};

	const componentData =
		data?.bannerButtonImageContainerCollection?.items[0] || [];
	const buttonData = componentData?.buttonCollection?.items;
	const textData = componentData?.textImageSection;
	const imageData = textData?.sectionImage;
	const heading = textData?.heading.json as Document;
	const richTextDescription = textData?.richTextDescription?.json as Document;

	const announcementHeight: AnnouncementHeightProps = {
		'--announcement-banner-height': `${announcementBannerHeight}px`,
	};

	return (
		<div
			className={`${styles.heroBanner} ${textData?.imagePosition === 'Right'
					? styles['heroBanner--imagePosition']
					: ''
				} ${textData?.equalWidth ? styles['heroBanner--equalWidth'] : ''} ${componentData?.backgroundColour
					? styles['heroBanner--' + componentData?.backgroundColour]
					: ''
				} ${componentData?.isFirstComponent
					? styles['heroBanner--firstComponent']
					: ''
				}
			`}
			style={announcementHeight}
			data-aos="fade-up"
		>
			<figure className={styles.imageContent} data-aos="fade-up">
				{imageData?.url && (
					<Image
						src={imageData.url}
						alt={imageData.title}
						width={1000}
						height={1000}
						style={{ objectPosition: textData?.imageBackgroundPosition }}
						priority
					/>
				)}
			</figure>
			<article className={`${styles.textContent}`}>
				{textData?.headingTagline && (
					<span data-aos="fade-up">{textData.headingTagline}</span>
				)}

				{heading && documentToReactComponents(heading, options)}

				{textData?.subtitle && <p data-aos="fade-up">{textData.subtitle}</p>}
				{richTextDescription &&
					documentToReactComponents(richTextDescription, options2)}
				<div className={styles.ctaSection}>
					{componentData.containsStartAQuoteButton && (
						<StepperButton title="Start a quote" paletteVariant='primary' />
					)}
					{componentData.containsBookACallButton && (
						<CalendlyButton title="Book a call" paletteVariant='primary' />
					)}
					{buttonData &&
						buttonData.map((button, index) => (
							<Button
								title={button.title}
								href={button.buttonUrl}
								textColor={button.textColour}
								backgroundColor={button.backgroundColour}
								borderColor={button.borderColour}
								type={button.type}
								openInNewTab={button.openInNewTab}
								key={index}
								data-aos="fade-up"
							/>
						))}
				</div>
			</article>
		</div>
	);
}

export default HeroBanner;
