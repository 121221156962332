'use client';
import { Image } from '@bw/elements';
import { AgencyDetails } from '@bw/external-apis/shared';
import { withoutThe } from '../../../../helpers/agencyUtils';
import { PeopleInOffice_inLogo } from '../../../../stock-images';
import { useAppContext } from '../../../form-stepper/StepperProvider';
import { ShortForm } from '../../short-form/ShortForm';
import styles from './AgencyTeamHeroSection.module.scss';

export interface AgencyTeamHeroSectionProps {
	agencyDetails: AgencyDetails;
	heroImageUrl?: string;
}
type AnnouncementHeightProps = Record<string, string>;
export function AgencyTeamHeroSection({
	agencyDetails,
	heroImageUrl,
}: AgencyTeamHeroSectionProps) {
	const { agency } = agencyDetails;
	const { announcementBannerHeight } = useAppContext();
	const announcementHeight: AnnouncementHeightProps = {
		'--announcement-banner-height': `${announcementBannerHeight}px`,
	};

	return (
		<section className={styles.AgencyHeroImageFormSection}>
			<div className="container container--max">
				<div
					className={`${styles.AgencyHeroimageColTextForm} ${styles['AgencyHeroimageColTextForm--imageWithFormHeading']}`}
					style={announcementHeight}
				>
					<figure className={styles.imageSection} data-aos="fade-up">
						<Image
							alt="agency team hero image"
							src={heroImageUrl ?? PeopleInOffice_inLogo}
							width={1500}
							height={1000}
						/>
					</figure>
					<article className={styles.textSection}>
						<h1 data-aos="fade-up">
							Meet Your {withoutThe(agency.marketingName)} Brightway Insurance
							Team
						</h1>
						<p data-aos="fade-up">
							The key to exceptional insurance coverage starts with an agent who
							truly understands your needs. Our{' '}
							{withoutThe(agency.marketingName)} team lives right here in the
							community. We know the unique risks, assets, and lifestyles of{' '}
							{agencyDetails.intro.city || agency.city} residents.
						</p>
						<span className={styles.formTagline} data-aos="fade-up">
							Let&apos;s talk.
						</span>
						<ShortForm agencyDetails={agencyDetails} />
					</article>
				</div>
			</div>
		</section>
	);
}
