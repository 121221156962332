export default function processWebsiteLink(websiteLink) {
	// Regular expression to match a valid URL starting with http://, https://, or www.

	const urlRegex =
		/^(https?:\/\/(www\.)?)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9]+)+(\.[a-zA-Z]{2,})?(\/[^\s]*)?$/i;

	const firstLink = websiteLink?.split(' ')[0];
	// Check if the websiteLink matches the regular expression
	if (urlRegex.test(firstLink)) {
		if (firstLink.startsWith('http://') || firstLink.startsWith('https://')) {
			// If the URL already starts with http:// or https://, return the same variable
			return firstLink;
		} else {
			// If the URL doesn't start with http(s)://, add https:// to the beginning
			return 'https://' + firstLink;
		}
	}

	// If the paymentLink doesn't match the regular expression or is invalid, return false
	return false;
}
