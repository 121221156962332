'use client';
import { AgencyDetails } from '@bw/external-apis/shared';
import { setCookie } from 'cookies-next';
import { FC, useEffect } from 'react';
import SiteHeader from '../../header/header';
import { Header } from '../../header/types';
import { buildHeaderData } from './buildHeaderData';

export const AgencyHeader: FC<{
	agencyDetails: AgencyDetails;
	insuranceTypeId?: string;
	loginUrl: string;
}> = ({ agencyDetails, insuranceTypeId, loginUrl }) => {

	useEffect(() => {
		if (insuranceTypeId && typeof localStorage !== 'undefined') {
			localStorage.setItem('leadInsuranceTypeId', insuranceTypeId);
		}
		if (typeof localStorage !== 'undefined') {
			localStorage.setItem('storeNumber', agencyDetails.agency.storeNumber);
		}

		setCookie('visitedAgentId', agencyDetails.agency.id);
	}, []);

	const stateAbbrForUrl = agencyDetails.agency.stateAbbreviation.toLowerCase();

	const data = buildHeaderData(agencyDetails, stateAbbrForUrl, loginUrl);
	const headerData: Header = data.headerCollection.items[0] || [];

	return <SiteHeader headerData={headerData} pageCategories={['Agencies']} />;
};
