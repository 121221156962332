import { LogoVector } from '@bw/elements/icons';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import styles from './CompaniesSearchBanner.module.scss';

export default function CompaniesSearchBanner({ bannerData }) {
	const { heading, description } = bannerData;
	const descriptionData = description.json;
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => {
				return (
					<p
						data-aos="fade-up"
						data-aos-delay="100"
						id="companies-we-represent-page-data"
					>
						{children}
					</p>
				);
			},
			[INLINES.HYPERLINK]: (node: any) => {
				return (
					<a href={node.data.uri} title={node.content[0].value}>
						{node.content[0].value}
					</a>
				);
			},
		},
	};
	return (
		<section className={styles['CompaniesBanner']}>
			<div className="container container--max">
				<div className="container">
					<div className="banner__content">
						<h1 data-aos="fade-up">{heading}</h1>
						{documentToReactComponents(descriptionData, options)}
					</div>
				</div>
				<figure className="banner__logo-layer">
					<LogoVector
						data-aos="fade-left"
						data-aos-delay="200"
						width={141}
						height={286}
					/>
				</figure>
			</div>
		</section>
	);
}
