'use client';
import { useSuspenseQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { Image } from '@bw/elements';
import type { ChangeEvent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FORM_ERROR } from '../../constants';
import { GET_IMAGE_COL_TEXT_FORM_ITEMS } from '../../queries';
import { ThemeColor } from '../../theme';
import { ButtonProps } from '../../types/type';
import { useAppContext } from '../form-stepper/StepperProvider';
import FormButton from '../utility/formButton/formButton';
import styles from './ImageColTextForm.module.scss';

export interface ImageWithTextForm {
	formId: string;
	subHeading: string;
	heading: string;
	description: string;
	formHeading: string;
	optionsItems: string[];
	dropdownHeading: string;
	button: ButtonProps;
	image: {
		image: {
			title: string;
			url: string;
			width: number;
			height: number;
		};
	};
	isImageSmall: boolean;
	borderColour: ThemeColor;
}

export interface ImageWithTextFormCollectionItem {
	imageWithTextFormCollection: {
		items: ImageWithTextForm[];
	};
}

interface ImageColTextFormProps {
	name: string;
}

type Inputs = {
	leadInsuranceTypeId: string;
	postalCode: number;
};

export function ImageColTextForm(props: ImageColTextFormProps) {
	//fetching data
	const { data } = useSuspenseQuery<ImageWithTextFormCollectionItem>(
		GET_IMAGE_COL_TEXT_FORM_ITEMS,
		{
			variables: {
				component: props.name,
			},
		}
	);

	const componentData = data.imageWithTextFormCollection.items[0];
	const buttonData = componentData?.button;
	const imageData = componentData?.image?.image;
	const optionItems = componentData?.optionsItems;
	const isImageSmall = componentData.isImageSmall;

	const { showFormStepper, setShowFormStepper } = useAppContext();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<Inputs>();
	const onSubmit: SubmitHandler<Inputs> = (data) => {
		localStorage.setItem('leadInsuranceTypeId', data.leadInsuranceTypeId);
		localStorage.setItem('postalCode', data.postalCode.toString());
		setShowFormStepper(!showFormStepper);
		reset();
	};

	const handleOnInput = (e: ChangeEvent<HTMLInputElement>) => {
		e.target.value = e.target.value.replace(/[^0-9]/g, '');
	};

	return (
		<section>
			<div className="container container--max">
				<div
					data-aos="fade-up"
					className={`${styles['imageColTextForm']}  ${
						isImageSmall ? styles['imageColTextForm--imageSmallDesign'] : ''
					}`}
				>
					<article className={styles.textSection}>
						{componentData?.subHeading && (
							<span className="headingTagline" data-aos="fade-up">
								{componentData.subHeading}
							</span>
						)}
						<h2 data-aos="fade-up">{componentData?.heading}</h2>
						<p className={styles.headingDescriptionText} data-aos="fade-up">
							{componentData?.description}
						</p>
						<form
							id={componentData?.formId}
							data-aos="fade-up"
							onSubmit={handleSubmit(onSubmit)}
						>
							<input type="hidden" name="gclid" />
							<input type="hidden" name="ga_id" />

							{componentData?.formHeading && (
								<h3 className={styles.formHeading}>
									{componentData?.formHeading}
								</h3>
							)}
							<label htmlFor="start-a-quote-dropdown">
								{componentData?.dropdownHeading
									? componentData.dropdownHeading
									: "I'm Interested in"}
							</label>
							<select
								id="start-a-quote-dropdown"
								{...register('leadInsuranceTypeId', {
									required: FORM_ERROR.REQUIRED,
								})}
								defaultValue=""
							>
								<option value="">Select Insurance</option>
								{optionItems &&
									optionItems.map((item, index) => (
										<option key={index} value={index + 1}>
											{item}
										</option>
									))}
							</select>

							<span>
								{errors['leadInsuranceTypeId']
									? errors['leadInsuranceTypeId'].message
									: ''}
							</span>
							<label htmlFor="postalCode">Zip Code</label>
							<input
								type="text"
								id="postalCode"
								inputMode="numeric"
								onInput={handleOnInput}
								minLength={5}
								maxLength={5}
								{...register('postalCode', {
									required: FORM_ERROR.REQUIRED,
									pattern: {
										value: /^(?!00000)\d{5}$/,
										message: 'Enter a valid postal code',
									},
								})}
							/>

							<span>
								{errors['postalCode'] ? errors['postalCode'].message : ''}
							</span>

							<div className={styles.buttonContainer}>
								<FormButton
									id="stepper-form"
									title={buttonData.title}
									textColor={buttonData.textColour}
									backgroundColor={buttonData.backgroundColour}
									borderColor={buttonData.borderColour}
									type="submit"
								/>
							</div>
						</form>
					</article>
					<figure className={styles.imageSection} data-aos="fade-up">
						{imageData?.url && (
							<Image
								alt={imageData?.title}
								src={imageData.url}
								width={imageData.width}
								height={imageData.height}
							/>
						)}
					</figure>
				</div>
			</div>
		</section>
	);
}

export default ImageColTextForm;
