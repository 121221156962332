import { OfficeHours } from "@bw/external-apis/shared";

export const getOfficeHoursDisplay = (officeHours: OfficeHours): string[] => {
  const { weekdayHours, weekendHours } = officeHours;
  const schedule: { day: string, hours: string }[] = [...weekdayHours, ...weekendHours].map(daySchedule => ({
    day: getDayOfWeek(daySchedule.dayOfWeek),
    hours: daySchedule.displayHours
  }));

  return groupDaysByHours(schedule);
}

const getDayOfWeek = (dayOfWeek: number): string =>
  ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];

/* Outputs in the form of:
      "Mon-Fri: 9:00 AM - 5:00 PM",
      "Sat: 9:00 AM - 3:00 PM",
      "Sun: Closed"

*/
const groupDaysByHours = (schedule: { day: string, hours: string }[]): string[] => {
  let firstDayOfGroup = schedule[0];
  let currentGroupLabel = firstDayOfGroup.day;
  for (let i = 1; i < schedule.length; i++) {
    const currentDay = schedule[i];

    if (firstDayOfGroup.hours === currentDay.hours) {
      currentGroupLabel = `${firstDayOfGroup.day}-${currentDay.day}`;
      schedule.splice(i, 1);
      i--;
    }
    else {
      firstDayOfGroup.day = currentGroupLabel;
      firstDayOfGroup = currentDay;
      currentGroupLabel = currentDay.day;
    }
  }
  schedule[schedule.length - 1].day = currentGroupLabel;
  return schedule.map(daySchedule => `${daySchedule.day}: ${daySchedule.hours}`);
}
