import { slugify } from "@bw/core";
import { AgencyDetails, AgencyInsuranceType } from "@bw/external-apis/shared";
import { Brightway_Logo, man } from "../../../stock-images";
import { HeaderData, SubMenuItem } from "../../header/types";

const byInsuranceTypeSortOrder = (
	a: AgencyInsuranceType,
	b: AgencyInsuranceType
) => {
	return a.insuranceTypeSortOrder - b.insuranceTypeSortOrder;
};

export const buildHeaderData = (agencyDetails: AgencyDetails, stateAbbrForUrl: string, loginUrl: string): HeaderData => ({
  headerCollection: {
    total: 1,
    items: [
      {
        containsBookACallButton: false,
        containsStartAQuoteButton: true,
        title: 'Main Header',
        logo: {
          title: 'Brightway Insurance',
          imageUrl: `/agencies/${stateAbbrForUrl}/${slugify(
            agencyDetails.agency.city
          )}/${agencyDetails.agency.storeNumber}`,
          altText: 'Brightway Insurance',
          image: {
            title: 'Brightway Insurance',
            description: '',
            url: Brightway_Logo,
            width: 167,
            height: 32,
          },
        },
        menuItemsCollection: {
          items: [
            {
              __typename: 'MenuItem',
              title: 'Find Insurance',
              url: '/insurance',
              hideMenuActionSection: false,
              containsBookACallFunctionality: false,
              containsDualList: true,
              subMenuCollection: {
                items: [
                  ...agencyDetails.agency.agencyInsuranceTypes
                    .sort(byInsuranceTypeSortOrder)
                    .map<SubMenuItem>((type) => ({
                      __typename: 'TextUrl',
                      hideMenuActionSection: true,
                      containsBookACallFunctionality: false,
                      title: type.insuranceTypeName,
                      text: type.insuranceTypeName,
                      url: `/agencies/${stateAbbrForUrl}/${slugify(
                        agencyDetails.agency.alias
                      )}/${
                        agencyDetails.agency.storeNumber
                      }/services/${type.insuranceTypeAlias.toLowerCase()}`,
                    })),
                ],
              },
            },
            {
              __typename: 'TextUrl',
              text: 'About Us',
              title: 'About Us',
              hideMenuActionSection: false,
              containsBookACallFunctionality: false,
              url: `/agencies/${stateAbbrForUrl}/${slugify(
                agencyDetails.agency.city
              )}/${agencyDetails.agency.storeNumber}/team`,
            },
            {
              __typename: 'MenuItem',
              title: 'Support',
              url: '/support',
              menuBannerImage: {
                title: 'Other Insurance',
                url: man,
                width: 982,
                height: 1360,
              },
              subMenuCollection: {
                items: [
                  {
                    __typename: 'TextUrl',
                    hideMenuActionSection: false,
                    title: 'Customer Service',
                    text: 'Customer Service',
                    url: `/agencies/${stateAbbrForUrl}/${slugify(
                      agencyDetails.agency.city
                    )}/${agencyDetails.agency.storeNumber}/customer-service`,
                  },
                  {
                    __typename: 'TextUrl',
                    title: 'Contact Us',
                    text: 'Contact Us',
                    url: `/agencies/${stateAbbrForUrl}/${slugify(
                      agencyDetails.agency.city
                    )}/${agencyDetails.agency.storeNumber}/contact-us`,
                    hideMenuActionSection: false,
                  },
                  {
                    __typename: 'TextUrl',
                    title: 'Log In',
                    text: 'Log In',
                    url: loginUrl,
                    hideMenuActionSection: false,
                  },
                ],
              },
            },
          ],
        },
        ctaLinksCollection: {
          items: [
            {
              title: agencyDetails.agency.phone,
              buttonUrl: 'tel:' + agencyDetails.agency.phone,
              textColour: 'white',
              backgroundColour: 'none',
              borderColour: 'none',
            },
            {
              title: 'Log In',
              buttonUrl: loginUrl,
              textColour: 'white',
              backgroundColour: 'none',
              borderColour: 'none',
            }
          ],
        },
        actionLinksCollection: {
          items: [
            {
              text: 'Make a payment',
              url: '/insurance/insurance-companies',
            },
            {
              text: 'Report a claim',
              url: '/insurance/insurance-companies',
            },
          ],
        },
      },
    ],
  },
})