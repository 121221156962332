'use client';
import { CarrierListItem } from '@bw/external-apis';
import AOS from 'aos';
import { useContext, useEffect, useRef } from 'react';
import { MyContext } from '../../helpers/context';
import { useAppContext } from '../form-stepper/StepperProvider';
import SearchItem from '../search-item/SearchItem';
import SearchSort from '../search-sort/SearchSort';
import styles from './SearchResult.module.scss';

export interface SearchResultProps {
	carrierList: CarrierListItem[];
}
type AnnouncementHeightProps = Record<string, string>;

export function SearchResult({ carrierList }: SearchResultProps) {
	const letterRefs = useRef({});
	const { searchQuery, setSearchQuery } = useContext(MyContext);
	const { setActiveAlphabet } = useContext(MyContext);
	const { announcementBannerHeight } = useAppContext();

	// Filter companies based on search query
	const filteredCompanies = carrierList.filter((company) =>
		company.companyName.toLowerCase().includes(searchQuery.toLowerCase())
	);

	// Group companies by the first letter of their names
	const groupedCompanies = filteredCompanies.reduce((acc, company) => {
		const firstLetter = withoutThe(company.companyName)[0].toUpperCase();
		if (!acc[firstLetter]) {
			acc[firstLetter] = [];
		}
		acc[firstLetter].push(company);
		return acc;
	}, {} as { string: CarrierListItem[] });

	// Get a list of all alphabets
	const allAlphabets = Array.from({ length: 26 }, (_, i) =>
		String.fromCharCode('A'.charCodeAt(0) + i)
	);

	// Sort the groups alphabetically
	const sortedGroups = allAlphabets.sort();
	// Ref to store references to alphabet sections
	const sectionRefs = useRef({});

	useEffect(() => {
		// Populate refs with section elements
		sortedGroups.forEach((letter) => {
			sectionRefs.current[letter] = letterRefs.current[letter];
		});

		let topmostAlphabet: string | null = null;
		const handleScroll = () => {
			// Loop through each alphabet section and check if it's in the viewport
			for (const letter of sortedGroups) {
				const element = sectionRefs.current[letter];
				if (element) {
					const rect = element.getBoundingClientRect();
					const isInViewport = rect.top <= 232 + 128 + announcementBannerHeight; // Adjust this threshold as needed
					if (isInViewport) {
						topmostAlphabet = letter;
					}
				}
			}

			setActiveAlphabet(topmostAlphabet);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [sortedGroups]);

	//handle button click
	const handleAlphabetClick = (letter) => {
		const element = sectionRefs.current[letter];
		if (element) {
			setActiveAlphabet(letter);
			window.scrollTo({
				behavior: 'smooth',
				top: element.offsetTop + (window.innerWidth < 768 ? 500 : 350),
			});
		}
	};

	useEffect(() => {
		AOS.init({ once: true }); // Set once: true to trigger animations only once
	}, []);

	const announcementHeight: AnnouncementHeightProps = {
		'--announcement-banner-height': `${announcementBannerHeight}px`,
	};

	return (
		<div className={styles['search__result']}>
			<SearchSort onAlphabetClick={handleAlphabetClick} />

			<section
				className="search__result-listing"
				data-aos="fade-up"
				data-aos-delay="450"
			>
				{sortedGroups.map((letter) => (
					<div
						key={letter}
						className={`search__result-byletter`}
						id={letter}
						ref={(el) => {
							letterRefs.current[letter] = el;
						}}
					>
						{groupedCompanies[letter] ? (
							<div className="container container--max">
								<div className="filter__tag" style={announcementHeight}>
									{letter}
								</div>
								<div className="container">
									<ul className="search__result-wrapper">
										{groupedCompanies[letter].sort(sortWithoutThe).map(
											(company: CarrierListItem) => (
												<li key={company.id} id="searchResults">
													<SearchItem company={company} />
												</li>
											)
										)}
									</ul>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
				))}
			</section>
		</div>
	);
}

const withoutThe = (str: string) => str.replace(/^The /, '');

/** Sorts strings while ignoring the word "The" if it comes at the beginning of the string */
const sortWithoutThe = (a: { companyName: string }, b: { companyName: string }) => 
	withoutThe(a.companyName).localeCompare(withoutThe(b.companyName));

export default SearchResult;
