'use client';
import { useContext, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { MyContext } from '../../helpers/context';
import { useAppContext } from '../form-stepper/StepperProvider';
import Button from '../utility/button/button';
import styles from './SearchSort.module.scss';

export interface SearchSortProps {
	onAlphabetClick: (letter: string) => void;
}
type AnnouncementHeightProps = Record<string, string>;

export function SearchSort({ onAlphabetClick }) {
	const { activeAlphabet } = useContext(MyContext);
	const [searchValue, setSearchValue] = useState('');
	const { setSearchQuery } = useContext(MyContext);
	const { announcementBannerHeight } = useAppContext();

	const handleAlphabetClick = (letter: string) => {
		onAlphabetClick(letter);
	};

	const debouncedSearchHandler = useDebouncedCallback((query) => {
		setSearchValue(query);
		setSearchQuery(query);
	}, 500); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

	const handleSearchChange = (event) => {
		const query = event.target.value;

		// Call the debounced search handler
		debouncedSearchHandler(query);
	};

	const searchHandler = () => {
		setSearchQuery(searchValue);
	};

	const formHandler = (event: React.FormEvent) => {
		setSearchQuery(searchValue);
		event.preventDefault(); // Prevent the default form submission
	};

	const announcementHeight: AnnouncementHeightProps = {
		'--announcement-banner-height': `${announcementBannerHeight}px`,
	};

	return (
		<div className={styles['search__sort']} style={announcementHeight}>
			<div className="container" data-aos="fade-up" data-aos-delay="300">
				<div className="banner__form">
					<form onSubmit={formHandler} id="search-insurance-companies">
						<input type="hidden" name="gclid" />
						<input type="hidden" name="ga_id" />

						<label htmlFor="search-company">Search Companies</label>
						<div className="form__group">
							<div className="form__input">
								<input
									type="text"
									id="search-company"
									onChange={handleSearchChange}
									aria-describedby="companies-we-represent-page-data"
								/>
							</div>
							<div
								className="form__button"
								onClick={searchHandler}
								aria-describedby="searchResults"
							>
								<Button
									title="Search"
									href="#"
									textColor="deepBlue"
									backgroundColor="brightYellow"
									borderColor="brightYellow"
									type="button"
								/>
							</div>
						</div>
					</form>
				</div>
				<div className="banner__scroll-to-sec">
					<ul>
						{Array.from({ length: 26 }, (_, i) =>
							String.fromCharCode('A'.charCodeAt(0) + i)
						).map((letter) => (
							<li key={letter}>
								<strong
									className={activeAlphabet === letter ? 'active' : ''}
									onClick={() => handleAlphabetClick(letter)}
									onKeyDown={(e) => {
										// Handle Enter key press
										if (e.key === 'Enter') {
											handleAlphabetClick(letter);
										}
									}}
									tabIndex={0}
								>
									{letter}
								</strong>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default SearchSort;
