'use client';
import { AgencyDetails, AgencyInsuranceType } from '@bw/external-apis/shared';
import { MapComponent } from '../../../map/MapComponent';
import { OfficeHours } from '../../office-hours/OfficeHours';
import styles from './InsuranceHalfTextHalfMap.module.scss';

export interface InsuranceHalfTextHalfMapProps {
	agencyDetails: AgencyDetails;
	insuranceType: AgencyInsuranceType;
	mapsApiKey?: string;
}

export function InsuranceHalfTextHalfMap({ agencyDetails, insuranceType, mapsApiKey }: InsuranceHalfTextHalfMapProps) {
	const city = agencyDetails.intro.city || agencyDetails.agency.city;

	return (
		<section className={styles.insuranceHalfTextHalfMapSection}>
			<div className="container container--max">
				<div className={styles.insuranceHalfTextHalfMap} data-aos="fade-up">
					<article className={styles.textSection}>
						<div>
							<h3 data-aos="fade-up">
								Visit us to learn more about {city}{' '}
								<b>
									{insuranceType.insuranceTypeId === 1
										? 'Homeowners'
										: insuranceType.insuranceTypeName}{' '}
									Insurance
								</b>
							</h3>
						</div>
						<h4 data-aos="fade-up">
							We&apos;re eager to help you find the right insurance online or
							in-person. Visit our office to meet your new insurance team.o the
							team that’s here to have your back.
						</h4>
						<OfficeHours officeHours={agencyDetails.officeHours} />
					</article>
					<div className={styles.mapSection} data-aos="fade-up">
						<MapComponent agencyDetails={agencyDetails.agency} apiKey={mapsApiKey} />
					</div>
				</div>
			</div>
		</section>
	);
}
