'use client';
import { Image } from '@bw/elements';
import { AgencyDetails, AgencyInsuranceType } from '@bw/external-apis/shared';
import { useState } from 'react';
import {
    Boat_inLogo,
    CarCamper_inLogo,
    Caravan,
    CoupleMovingIn__3_,
    Dog_In_Window_Logo,
    DogandHouse_inLogo,
    FamilyBreakfast_inLogo,
    FamilyDancing_inLogo,
    FamilyOnPorch_inLogo,
    FamilybyWater_inLogo,
    FriendsCouch_inLogo,
    WomenStore_inLogo,
    horner,
} from '../../../../stock-images';
import styles from './InsuranceBoxDescriptionWithImageRollover.module.scss';
import { getInsuranceTexts } from './insuranceTexts';

export interface InsuranceBoxDescriptionWithImageRolloverProps {
	agencyDetails: AgencyDetails;
	insuranceType: AgencyInsuranceType;
}

export function InsuranceBoxDescriptionWithImageRollover({
	agencyDetails,
	insuranceType,
}: InsuranceBoxDescriptionWithImageRolloverProps) {
	const handleListImageChange = (imageUrl: string) => {
		setDisplayImage(imageUrl);
	};

	const handleMouseLeaveList = () => {
		setDisplayImage(initialDisplayImage(insuranceType));
	};
	const [displayImage, setDisplayImage] = useState(
		initialDisplayImage(insuranceType)
	);
	const city = agencyDetails.intro.city || agencyDetails.agency.city;

	let heading = '';
	switch (insuranceType.insuranceTypeId) {
		case 1:
			heading = `Typical ${city} Homeowners Insurance Policy`;
			break;
		case 2:
		case 3:
		case 5:
		case 6:
		case 7:
		case 8:
		case 15:
			heading = `Typical ${city} ${insuranceType.insuranceTypeName} Coverage`;
			break;
		case 9:
		case 11:
		case 12:
			heading = `Typical ${city} ${insuranceType.insuranceTypeName} Coverage Covers`;
			break;
		case 10:
			heading = 'Essential Business Insurance Coverage';
			break;
		default:
			heading = `Typical ${city} ${insuranceType.insuranceTypeName} Insurance Policy`;

			break;
	}

	// Call the function to get switch cases data
	const { paragraph, listData } = getInsuranceTexts(insuranceType);

	//change image

	function initialDisplayImage(type) {
		switch (type.insuranceTypeId) {
			case 1:
				return DogandHouse_inLogo;
			case 2:
				return FamilybyWater_inLogo;
			case 3:
				return FamilyBreakfast_inLogo;
			case 4:
				return FriendsCouch_inLogo;
			case 5:
				return CoupleMovingIn__3_;
			case 6:
				return CarCamper_inLogo;
			case 7:
				return horner;
			case 8:
				return Caravan;
			case 9:
				return Boat_inLogo;
			case 10:
				return WomenStore_inLogo;
			case 11:
				return FamilyDancing_inLogo;
			case 12:
				return FamilyOnPorch_inLogo;
			case 15:
				return Dog_In_Window_Logo;
			default:
				return DogandHouse_inLogo;
		}
	}

	return (
		<section className={styles.insuranceBoxDescriptionWithImageRolloverSection}>
			<div className="container container--max">
				<div
					className={styles.insuranceBoxDescriptionWithImageRollover}
					data-aos="fade-up"
				>
					<div className={styles.headWrapper}>
						<article className={styles.textContainer} data-aos="fade-up">
							<span className="headingTagline" data-aos="fade-up">
								Coverage
							</span>
							<h2 data-aos="fade-up">{heading}</h2>
							<p data-aos="fade-up">{paragraph}</p>
						</article>
						<figure className={styles.imageContainer} data-aos="fade-up">
							<Image
								src={displayImage}
								alt="banner image"
								width={1000}
								height={1000}
								priority={true}
							/>
						</figure>
					</div>
					<ul
						className={styles.listWrapper}
						onMouseLeave={handleMouseLeaveList}
					>
						{listData.map((list, index) => (
							<li
								key={index}
								className={styles.list}
								onMouseOver={() => {
									handleListImageChange(list.imageUrl);
								}}
							>
								<article className={styles.listContent}>
									<h3 data-aos="fade-up">{list.title}</h3>

									<p data-aos="fade-up">{list.description}</p>
								</article>
							</li>
						))}
					</ul>
				</div>
			</div>
		</section>
	);
}
