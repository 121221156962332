'use client';
import { AgencyDetails } from '@bw/external-apis/shared';
import type { ChangeEvent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FORM_ERROR } from '../../../constants';
import { useAppContext } from '../../form-stepper/StepperProvider';
import FormButton from '../../utility/formButton/formButton';
import { InsuranceTypeSelect } from '../insurance-type-select/InsuranceTypeSelect';

type Inputs = {
	insuranceTypeId: string;
	zip: string;
};

type ShortFormProps = {
	insuranceTypeId: number;
	agencyDetails?: never;
}	| {
	agencyDetails: AgencyDetails;
	insuranceTypeId?: never;
}

export const ShortForm = ({
	agencyDetails,
	insuranceTypeId,
}: ShortFormProps) => {
	const { showFormStepper, setShowFormStepper } = useAppContext();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<Inputs>();
	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const storeNumber = agencyDetails?.agency.storeNumber;
		if (storeNumber) {
			localStorage.setItem('storeNumber', storeNumber);
		}
		localStorage.setItem('leadInsuranceTypeId', data.insuranceTypeId);
		localStorage.setItem('postalCode', data.zip.toString());
		setShowFormStepper(!showFormStepper);
		reset();
	};

	const handleOnInput = (e: ChangeEvent<HTMLInputElement>) => {
		e.target.value = e.target.value.replace(/[^0-9]/g, '')
	}

	return (
		<>
			<form
				data-aos="fade-up"
				onSubmit={handleSubmit(onSubmit)}
				id="stepper form"
			>
				<fieldset>
					<input type='hidden' name='gclid' />
					<input type='hidden' name='ga_id' />

					{insuranceTypeId ? (
						<input
							type="hidden"
							data-aos="fade-up"
							value={insuranceTypeId}
							{...register('insuranceTypeId')}
						/>
					) : (
						agencyDetails && (
							<InsuranceTypeSelect
								insuranceTypes={agencyDetails.agency.agencyInsuranceTypes}
								{...register('insuranceTypeId')}
							/>
						)
					)}

					<label htmlFor="zip" data-aos="fade-up">
						Zip Code
					</label>
					<input
						type="text"
						data-aos="fade-up"
						id="zip"
						inputMode='numeric'
						minLength={5}
						maxLength={5}
						onInput={handleOnInput}
						{...register('zip', {
							required: FORM_ERROR.REQUIRED,
							pattern: {
								value: /^(?!00000)\d{5}$/,
								message: 'Enter a valid zip code',
							},
						})}
					/>
					<span>{errors['zip'] ? errors['zip'].message : ''}</span>
					<FormButton
						title="Get started"
						type="submit"
					/>
				</fieldset>
			</form>
		</>
	);
};
