'use client';

import { useEffect, useState } from 'react';
import CountUp from "react-countup";
import { useInView } from 'react-intersection-observer';


export const Counter = (props: { end: number }) => {
  const [countUpStarted, setCountUpStarted] = useState(false);
	const [ref, inView] = useInView({
		triggerOnce: true,
	});
	useEffect(() => {
		if (inView && !countUpStarted) {
			setCountUpStarted(true);
		}
	}, [inView, countUpStarted]);
  return <span ref={ref}>
    <CountUp
      start={0}
      end={props.end}
      duration={6}
      separator=","
    />
  </span>
}
