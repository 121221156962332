'use client';
import { useSuspenseQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { Image } from '@bw/elements';
import { LogoVector, Quote } from '@bw/elements/icons';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { useEffect, useState } from 'react';
import 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/mousewheel';
import 'swiper/css/scrollbar';
import { A11y, Autoplay, Mousewheel, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GET_AGENCY_OWNERS_LISTING } from '../../queries';
import styles from './PersonCardSliderSection.module.scss';

interface PersonCardSliderSectionDataProps {
	listingModelCollection: {
		items: { heading: string; personCardCollection: PersonCardData[] };
	};
}
interface PersonCardData {
	personName: string;
	designation: string;
	image: { image: { url: string; title: string } };
}

export function PersonCardSliderSection(props: { name: string }) {
	const [isClient, setIsClient] = useState(false);
	const { data } = useSuspenseQuery<PersonCardSliderSectionDataProps>(
		GET_AGENCY_OWNERS_LISTING,
		{
			variables: {
				component: props.name as string,
			},
		}
	);

	const componentData = data.listingModelCollection.items[0];
	const personData = componentData.personCardCollection.items;
	useEffect(() => {
		setIsClient(true);
	}, []);

	const options_method = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => {
				return <>{children}</>;
			},
		},
	};
	return (
		<div className={styles['PersonCardSliderSection']}>
			<div className="container">
				<div className="PersonCardSliderSection__header">
					<article>
						<h2 data-aos="fade-up">{componentData?.heading}</h2>
					</article>
					<figure data-aos="fade-up">
						<Quote width={107} height={80} />
					</figure>
				</div>
			</div>
			<div className="PersonCardSliderSection__wrapper">
				<Swiper
					modules={[Autoplay, Scrollbar, A11y, Mousewheel]}
					autoplay={{ delay: 20, disableOnInteraction: true }}
					loop={true}
					speed={10000}
					draggable={true}
					breakpoints={{
						375: {
							slidesPerView: 1.7,
							spaceBetween: 10,
						},
						767: {
							slidesPerView: 2.8,
							spaceBetween: 30,
						},
						1024: {
							slidesPerView: 3.7,
							spaceBetween: 40,
						},
						1440: {
							slidesPerView: 3.7,
							spaceBetween: 40,
						},
					}}
				>
					{personData.map((person, index) => (
						<SwiperSlide key={index}>
							<div className="PersonCardSliderSection__item">
								{person?.image?.image?.url && (
									<figure className="slider__photo">
										<Image
											src={person.image.image.url}
											alt={person.image.image.title}
											height={person.image.image.height}
											width={person.image.image.width}
										/>
									</figure>
								)}
								<p className="testimonial-name" data-aos="fade-up">
									{' '}
									{person?.personName}
								</p>
								<p className="testimonial-designation" data-aos="fade-up">
									{person?.designation}
								</p>
								{isClient ? (
									<p data-aos="fade-up">
										{documentToReactComponents(
											person?.details?.json,
											options_method
										)}
									</p>
								) : (
									<></>
								)}
								<figure className="logo__layer">
									<LogoVector width={141} height={286} />
								</figure>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
}

export default PersonCardSliderSection;
