'use client';
import { Image } from '@bw/elements';
import { heroImg } from '../../../../stock-images';
import { useAppContext } from '../../../form-stepper/StepperProvider';
import Button from '../../../utility/button/button';
import styles from './careers-hero-banner.module.scss';

interface CareersHeroBannerProps {
	marketingName: string;
	assessmentUrl: string | null;
}
type AnnouncementHeightProps = Record<string, string>;

export const CareersHeroBanner = ({
	marketingName,
	assessmentUrl,
}: CareersHeroBannerProps) => {
	const { announcementBannerHeight } = useAppContext();
	const announcementHeight: AnnouncementHeightProps = {
		'--announcement-banner-height': `${announcementBannerHeight}px`,
	};
	return (
		<div
			className={`${styles.heroBanner} ${styles['heroBanner--imagePosition']} `}
			data-aos="fade-up"
			style={announcementHeight}
		>
			<figure className={styles.imageContent} data-aos="fade-up">
				<Image src={heroImg} alt="Hero" width={1000} height={1000} />
			</figure>
			<article className={`${styles.textContent}`}>
				<h5 className="headingTagline" data-aos="fade-up">
					Careers
				</h5>

				<h1>Come grow with Brightway, {marketingName}!</h1>
				{assessmentUrl && (
					<>
						<p data-aos="fade-up">
							If you&apos;re interested in a career with our agency, please
							click the button below to take an online assessment. Not only will
							we receive the results, but so will you!
						</p>

						<Button
							title="Take the Assessment"
							href={assessmentUrl}
							textColor="deepBlue"
							backgroundColor="brightYellow"
							borderColor="none"
							type="button"
							openInNewTab={true}
						/>
					</>
				)}
			</article>
		</div>
	);
};
