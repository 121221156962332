'use client';
import { LoggerEvents, logger } from '@bw/core';
import { Image } from '@bw/elements';
import { LoaderBlue } from '@bw/elements/icons';
import { AgencyDetails } from '@bw/external-apis/shared';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FORM_ERROR } from '../../../../constants';
import { ApiContext } from '../../../../server';
import { contactusformImage } from '../../../../stock-images';
import FormButton from '../../../utility/formButton/formButton';
import { OfficeHours } from '../../office-hours/OfficeHours';
import styles from './AgencyContactUsForm.module.scss';

export type ContactUsRequestState =
	| {
			status: 'ready' | 'loading' | 'success';
	  }
	| {
			status: 'error';
			error: Error;
	  };
type Inputs = {
	name: string;
	email: string;
	comments: string;
};

export interface AgencyContactUsFormProps {
	agencyDetails: AgencyDetails;
	submitAgencyContactForm: ApiContext['agencyContact']['submitAgencyContactForm'];
}

export function AgencyContactUsForm({	agencyDetails, submitAgencyContactForm }: AgencyContactUsFormProps) {
	const [requestState, setRequestState] = useState<ContactUsRequestState>({
		status: 'ready',
	});
	const [hideForm, setHideForm] = useState<boolean>(false);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const getCaptchaToken = async (
		action: string | undefined
	): Promise<string> => {
		const message =
			'executeRecaptcha not available on form submit, possible react script loading error.';
		if (!executeRecaptcha) {
			logger.error(LoggerEvents.GeneralErrorWebApp, { message });
			return 'no recaptcha';
		}
		try {
			const token = await executeRecaptcha(action);
			return token;
		} catch (error) {
			logger.error(LoggerEvents.GeneralErrorWebApp, { message });
		}
		return 'no recaptcha';
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<Inputs>();
	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		setRequestState({ status: 'loading' });
		setHideForm(true);
		const captchaToken = await getCaptchaToken('agencyContactUsForm');
		try {
			const response = await submitAgencyContactForm({...data, captchaToken, storeNumber: agencyDetails.agency.storeNumber });

			if (typeof response.error === 'string') {
				throw new Error(response.error);
			}
			setRequestState({ status: 'success' });
			reset();
		} catch (error) {
			if (error instanceof Error) {
				setRequestState({ status: 'error', error });
				setHideForm(false);
			}
		}
	};
	const ThanksDisplay = ({ state }: { state: ContactUsRequestState }) => {
		return (
			<>
				{state.status === 'success' && (
					<div className={styles.success}>
						<h5 className="headingTagline">Submission Successful!</h5>
						<h2>Thank you!</h2>
						<p>Check your email. We’ll be in touch soon!</p>
					</div>
				)}
			</>
		);
	};
	const ErrorDisplay = ({ state }: { state: ContactUsRequestState }) => {
		return (
			<>
				{state.status === 'error' && (
					<div className={styles.error}>{state.error.message}</div>
				)}
			</>
		);
	};
	const LoadingDisplay = ({ state }: { state: ContactUsRequestState }) => {
		return (
			<>
				{state.status === 'loading' && (
					<div className="is__pageloader">
						<figure>
							<LoaderBlue width={100} height={100} />
						</figure>
					</div>
				)}
			</>
		);
	};
	const clearErrorState = () => {
		if (requestState.status === 'error') {
			setRequestState({ status: 'ready' });
		}
	};
	return (
		<section className={styles.AgencyContactUsSection}>
			<div className="container container--max">
				<div className={styles.ContactUsForm}>
					<article>
						{!hideForm ? (
							<>
								<h1 data-aos="fade-up">We&apos;d love to hear from you.</h1>
								<form
									id="agency-page-contact-us-form"
									className={styles.formSection}
									data-aos="fade-up"
									onSubmit={handleSubmit(onSubmit)}
									onChange={clearErrorState}
								>
									<fieldset>
										<input type="hidden" name="gclid" />
										<input type="hidden" name="ga_id" />

										<label htmlFor="name" data-aos="fade-up">
											Name
										</label>
										<input
											id="name"
											type="text"
											{...register('name', {
												required: FORM_ERROR.REQUIRED,
												pattern: {
													value: /^(?!^\s+$)[\w\s'-]{1,100}$/,
													message: FORM_ERROR.NAME,
												},
											})}
											data-aos="fade-up"
										/>
										<span>{errors['name'] ? errors['name'].message : ''}</span>

										<label htmlFor="email" data-aos="fade-up">
											Email
										</label>
										<input
											id="email"
											type="text"
											{...register('email', {
												required: FORM_ERROR.REQUIRED,
												pattern: {
													value:
														/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
													message: FORM_ERROR.EMAIL,
												},
											})}
											data-aos="fade-up"
										/>
										<span>
											{errors['email'] ? errors['email'].message : ''}
										</span>
										<label htmlFor="comments" data-aos="fade-up">
											Let us know how we can help
										</label>
										<textarea
											id="comments"
											{...register('comments', {
												required: FORM_ERROR.REQUIRED,
											})}
											data-aos="fade-up"
										/>
										<span>
											{errors['comments'] ? errors['comments'].message : ''}
										</span>
										<FormButton
											title="Send"
											containsSubmitIcon={false}
											type="submit"
										/>
										<ErrorDisplay state={requestState} />
									</fieldset>
								</form>
							</>
						) : (
							<LoadingDisplay state={requestState} />
						)}
						<ThanksDisplay state={requestState} />
					</article>

					<div className={styles.TextImageSection}>
						<div className={styles.TextSection}>
							<div className={styles.KeyAndValue}>
								<p data-aos="fade-up">Give us a call</p>
								<a
									href={'tel:' + agencyDetails.agency.phone}
									title={agencyDetails.agency.phone}
									data-aos="fade-up"
								>
									{agencyDetails.agency.phone}
								</a>
							</div>
							{!agencyDetails.agency.corporateHideAddress && (
								<div className={styles.KeyAndValue}>
									<p data-aos="fade-up">Drop By</p>
									{/* TODO(khaney): use AgencyAddress? (would nest <address> inside <h6>) */}
									<span data-aos="fade-up">
										{agencyDetails.agency.address1}{' '}
										{agencyDetails.agency.address2}
										<br />
										{agencyDetails.agency.city},{' '}
										{agencyDetails.agency.stateAbbreviation}{' '}
										{agencyDetails.agency.postalCode}
									</span>
								</div>
							)}
							<div className={styles.KeyAndValue} data-aos="fade-up">
								<OfficeHours
									officeHours={agencyDetails.officeHours}
									Container="span"
								/>
							</div>
						</div>
						<figure className={styles.ImageContainer} data-aos="fade-up">
							<Image
								src={contactusformImage}
								alt="form image"
								width={600}
								height={311}
							/>
						</figure>
					</div>
				</div>
			</div>
		</section>
	);
}
