import * as img from '../../../../stock-images';

export const getInsuranceTexts = (insuranceType) => {
	let listData: Array<{title:string,description:string, imageUrl: string}> = [];
	let paragraph = '';
	switch (insuranceType.insuranceTypeId) {
		case 1:
			paragraph =
				'Tapping into our deep well of expertise and a vast network of insurers, we fine-tune your coverage for a glove-like fit, ensuring premium protection without the premium price tag.';
			listData = [
				{
					title: 'Personal Liability',
					description:
						"Stand strong against the claims of accidents or mishaps that happen on your watch. We've got your back with coverage that kicks in if you or a family member are held responsible for injuries or personal property damage.",
					imageUrl: img.SmallModernHouse,
				},
				{
					title: 'Building Property',
					description:
						"From the ground up, your dwelling coverage is covered. Whether it's a shingle out of place or a more serious structural concern, rest assured your sanctuary is shielded from the surprises life throws your way.",
					imageUrl: img.CoupeandRealtor,
				},
				{
					title: 'Personal Property',
					description:
						'Your gadgets, your comfortable couch, your treasured items — we protect the things that make your house a home from theft, fire, and the unexpected.',
					imageUrl: img.CoupleMoving_inLogo,
				},
				{
					title: 'Water Damage',
					description:
						" Leaks and overflows can happen, but they don't have to soak your peace of mind. We're here to help keep your home dry and secure, no matter what drips or pours.",
					imageUrl: img.plumping,
				},
				{
					title: 'Guest Medical',
					description:
						'If a friend or family member gets injured under your roof, we’re here to help cover their medical costs.',
					imageUrl: img.homeInsurance,
				},
			];
			break;
		case 2:
			paragraph =
				"As part of the National Flood Insurance Program (NFIP), managed by FEMA, our policies provide you with comprehensive protection. When you choose a flood insurance policy through us, you're selecting a plan backed by a solid program designed to offer peace of mind in times of need.";
			listData = [
				{
					title: 'Home and Foundation',
					description:
						'Covers the brick and mortar of your home, keeping you secure up to the full rebuild value if floods try to wash away your hard work.',
					imageUrl: img.FamilybyWater_inLogo,
				},
				{
					title: 'Personal Items Protection',
					description:
						"Your treasures aren't just things. They're parts of your story. From your comfy sofa to your tech gadgets, we make sure they're defended against flood damage.",
					imageUrl: img.CoupleMoving_inLogo,
				},
				{
					title: 'Living Expense Coverage',
					description:
						"Got to move out while your place gets a facelift after a flood? We've got the extra costs covered, so you've got one less worry.",
					imageUrl: img.Hotel,
				},
				{
					title: 'Extended Options',
					description:
						'For those who prefer an extra umbrella on a rainy day, we offer expanded coverage. Think of it as an extra layer for pools, patios, and more.',
					imageUrl: img.ManOutside_inLogo,
				},
			];
			break;
		case 3:
			paragraph =
				"Condo insurance doesn't have to be confusing. With us, it's a clear path to protection. We pledge to guide you to a policy that fits just right, no excess, no shortfalls. By comparing top options, you not only get comprehensive coverage but also the assurance of a quality investment.";
			listData = [
				{
					title: 'Personal Property',
					description:
						'From your favorite jeans to your tech gadgets and your living room couch — we protect the essentials that personalize your condo.',
					imageUrl: img.CoupleMoving_inLogo,
				},
				{
					title: 'Liability Guard',
					description:
						' Slippery rugs and oops moments in your condo can lead to legal woes. This coverage is your safeguard, keeping you and your family clear from the fallout of at-home accidents.',
					imageUrl: img.FamilyDancing_inLogo,
				},
				{
					title: 'Building’s Backbone',
					description:
						"Beyond your condo's door, common areas also need protection. While typically covered by your condo association's insurance policy, we'll help ensure there are no gaps in your safety net.",
					imageUrl: img.FriendsCouch_inLogo,
				},
				{
					title: 'Guest Care',
					description:
						'Friends over? Great! If they take a tumble or encounter a mishap, we’re here to handle the unexpected and cover their medical expenses.',
					imageUrl: img.ManAndBabyStudio_inLogo,
				},
			];
			break;
		case 4:
			paragraph =
				'We do the legwork to find you the right insurance company that matches your renting lifestyle, providing solid protection without breaking the bank. Our goal is to simplify the insurance process and make it hassle-free for you. Get your renters insurance quote today and learn about your deductible options.';
			listData = [
				{
					title: 'Personal Belongings',
					description:
						'Your stuff — from tech toys to your wardrobe and couch — stays safe from theft, fire, and those surprise moments life throws at you. This is the essence of insurance coverage for your personal property.',
					imageUrl: img.CoupleMoving_inLogo,
				},
				{
					title: 'Liability Coverage',
					description:
						"If someone gets hurt at your place or you accidentally damage someone else's things, we're your financial shield against legal claims. Understand your deductible options to balance your coverage and budget.",
					imageUrl: img.Dog_In_Window_Logo,
				},
				{
					title: 'Temporary Relocation',
					description:
						"Can't stay in your rental after something unexpected? We'll cover your temporary digs until you can head back.",
					imageUrl: img.Hotel,
				},
				{
					title: 'High-Value Item Protection',
					description:
						'Got some treasures like fine jewelry or high-end tech? We’ll make sure they’re covered just right.',
					imageUrl: img.Ornaments,
				},
				{
					title: 'Guest Medical',
					description:
						'If a friend gets injured while at your place, don’t sweat the medical costs. We’ve got them handled.',
					imageUrl: img.ManAndBabyStudio_inLogo,
				},
			];
			break;
		case 5:
			paragraph =
				'With a keen eye for detail, we navigate the ins and outs of your personal insurance needs. Our partnerships with top insurers ensure that you get premier protection without paying a premium.';
			listData = [
				{
					title: 'Item Assurance',
					description:
						"This isn't just about avoiding mishaps. It's about being prepared for them. Our coverage makes sure your valuables are protected, no matter what life throws their way.",
					imageUrl: img.insideHome,
				},
				{
					title: 'Treasure Coverage',
					description:
						"More than just belongings, we cover your treasures—jewelry, art, heirlooms—because we know they're irreplaceable.",
					imageUrl: img.Ornaments,
				},
				{
					title: 'All-Round Protection',
					description:
						"Whether it's the latest tech gadget or a vintage guitar, our comprehensive coverage means you're protected against theft, damage, and the unexpected.",
					imageUrl: img.Guitar,
				},
				{
					title: 'Guest Safety Net',
					description:
						"Accidents don't just affect you. If a guest encounters trouble with your insured items, we care for their medical needs.",
					imageUrl: img.ManAndBabyStudio_inLogo,
				},
			];
			break;
		case 6:
			paragraph =
				'Our focus is simple. To gear you up with a policy that fits like a glove. With a dashboard of options from various insurance companies, we steer you towards premium value without the premium cost, ensuring accessibility to the best insurance rates.';
			listData = [
				{
					title: 'Liability Assurance',
					description:
						'If an accident is your fault, this coverage helps with the other person’s expenses. It’s your financial shield against legal headaches, including property damage liability.',
					imageUrl: img.NewCarOwner_inLogo,
				},
				{
					title: 'Car Care',
					description:
						"Dents, crashes, or any car damage? We've got the repairs covered so you can get back on the road.",
					imageUrl: img.Dog_In_Window_Logo,
				},
				{
					title: 'Personal Item Protection',
					description:
						'Your car is more than a ride. It holds your most personal belongings. We cover those too—from laptops to luggage.',
					imageUrl: img.CoupleMoving_inLogo,
				},
				{
					title: 'Medical Coverage',
					description:
						" If you or your passengers get hurt in a crash, we help cover the medical bills, no matter who's at fault. This includes personal injury protection, a key component of our comprehensive auto insurance policy.",
					imageUrl: img.TakingBag,
				},
			];
			break;
		case 7:
			paragraph =
				"By understanding your unique riding needs, including those for dirt bikes and mopeds, we aim to align you with a insurance policy that fits just right. With access to a variety of insurance coverage options and providers, we'll rev up the process, presenting you with the best possible coverage at competitive rates.";
			listData = [
				{
					title: 'Rider Liability',
					description:
						'Safeguards you from potential financial burdens by covering bodily injuries or property damages you might inflict on others during a ride mishap. This liability coverage is a fundamental aspect of any motorcycle insurance policy.',
					imageUrl: img.BikeInBeach,
				},
				{
					title: 'Bike Protection',
					description:
						'Shields your motorcycle from damages, be it from a collision with another vehicle or unforeseen obstacles on the open road. This collision coverage is essential for every motorist.',
					imageUrl: img.Bike_in_road,
				},
				{
					title: 'Gear & Personal Belongings',
					description:
						"Ensures the safety of your personal items, whether it's your high-tech riding gear, gadgets, or tools, from theft, damage, or loss.",
					imageUrl: img.Bike_repair,
				},
				{
					title: 'Rider & Pillion Care',
					description:
						"Addresses medical expenses for both you and any passenger, ensuring care and recovery post any accident, no matter who's at fault. This is crucial especially when dealing with an uninsured motorist.",
					imageUrl: img.Couple_in_Bike,
				},
			];
			break;
		case 8:
			paragraph =
				"The world of RV insurance can be intricate. Let us guide you. As an experienced insurance company, we'll explore a broad range of insurance providers to guarantee you receive the ideal insurance policy with the best coverage options, including comprehensive insurance coverage and essential liability coverage.";
			listData = [
				{
					title: 'Liability Protection',
					description:
						'Safeguards you from potential costs stemming from bodily injury or property damage that you or family members may inflict on others while using the recreational vehicle.',
					imageUrl: img.RVinBeach,
				},
				{
					title: 'Vehicle Structure Protection',
					description:
						'Focuses on the physical integrity of your RV, accounting for any damage that might occur to the main structure during your travels or stays.',
					imageUrl: img.Relax_in_woods,
				},
				{
					title: 'Personal Belongings Coverage',
					description:
						'Ensures the valuables inside your RV – from appliances and electronics to personal items – remain protected against risks like theft, fire, and other unforeseen circumstances.',
					imageUrl: img.RV_camp,
				},
				{
					title: 'Guest Medical Coverage',
					description:
						'Attends to medical expenses for guests who might get injured while in your RV, regardless of them being a resident.',
					imageUrl: img.Family_Cooking,
				},
			];
			break;
		case 9:
			paragraph =
				"We’re dedicated to helping you navigate these waters. Our team will work tirelessly to source policies from a diverse range of insurance companies, ensuring you get full coverage that aligns with your needs and budget. We also offer competitive premiums and personalized boat insurance quotes to suit every boater's financial plan.";
			listData = [
				{
					title: 'Onboard Liability',
					description:
						'Covers bodily injury and property damage that you, or your family, may cause to others while onboard or in proximity to your boat.',
					imageUrl: img.PeopleBoatJumping,
				},
				{
					title: 'Hull and Structures',
					description:
						'This coverage pertains to the physical damage to the boat itself and any attached elements, like a cabin or deck.',
					imageUrl: img.BoatFrontView,
				},
				{
					title: 'Personal Belongings',
					description:
						'Protects the possessions you keep on your boat – be it fishing gear, electronics, clothing, or other valuables – against potential losses from theft, fire, and other hazards.',
					imageUrl: img.Ornaments,
				},
				{
					title: 'Guest Medical',
					description:
						'Should a non-resident guest sustain injuries while on your boat, this ensures their medical bills are covered.',
					imageUrl: img.Boat_sun_set,
				},
				{
					title: 'Towing',
					description:
						'In case of an emergency or mechanical failure, our boat insurance policy includes towing services to ensure you and your boat are safely brought back to shore.',
					imageUrl: img.Boat_side_view,
				},
				{
					title: 'Personal Watercraft',
					description:
						'This specific coverage caters to boat owners who also possess smaller personal watercraft, offering additional protection for these unique vessels.',
					imageUrl: img.Jetski,
				},
			];
			break;
		case 10:
			paragraph =
				"Our mission is to tailor the perfect policy for your unique business needs. By partnering with a diverse range of top-tier insurance companies, we aim to find you the best coverages at the most competitive prices. Whether you require general liability insurance, commercial auto insurance, or a comprehensive Business Owners Policy (BOP), we're here to provide personalized support and guidance.";
			listData = [
				{
					title: 'General Liability',
					description:
						'This protects against bodily injury or property damage claims made by third parties due to your business operations.',
					imageUrl: img.WomanOffice_inLogo,
				},
				{
					title: 'Commercial Property',
					description:
						'This safeguards the physical structure of your business premises, including shared common areas, against damages from events like fires, storms, or vandalism.',
					imageUrl: img.CoupeandRealtor,
				},
				{
					title: 'Business Personal Property ',
					description:
						'This covers business assets within your premises such as machinery, office furniture, computers, inventory, and other essential equipment against loss from theft, fire, and other unforeseen incidents.',
					imageUrl: img.CycleOutside,
				},
				{
					title: "Workers' Compensation",
					description:
						'Ensures medical expenses and wage replacement are covered if employees get injured or fall ill due to their job.',
					imageUrl: img.Workers,
				},
				{
					title: 'Professional Liability (Errors & Omissions)',
					description:
						'Protects against claims of negligence or harm from services rendered or advice given.',
					imageUrl: img.PeopleInOffice_inLogo,
				},
			];
			break;
		case 11:
			paragraph =
				"Life insurance isn't just about planning for the inevitable. It's about securing your family's peace of mind. We'll assist you in finding the right policy to provide the protection your loved ones deserve at a price that suits your budget.";
			listData = [
				{
					title: 'Death Benefit',
					description:
						'Provides a tax-free lump sum to your beneficiaries in the event of your passing, helping them maintain their financial stability.',
					imageUrl: img.NewCarOwner_inLogo,
				},
				{
					title: 'Income Replacement',
					description:
						"Ensures that your loved ones continue to receive financial support even after you're gone, covering everyday expenses and long-term care needs.",
					imageUrl: img.FamilyOnPorch_inLogo,
				},
				{
					title: 'Debt Payoff',
					description:
						'Helps settle outstanding debts such as mortgages, loans, or credit card balances, preventing your loved ones from inheriting financial burdens.',
					imageUrl: img.DogandHouse_inLogo,
				},
				{
					title: 'Final Expenses',
					description:
						'Covers funeral and burial costs, relieving your family of immediate financial strain during a difficult time.',
					imageUrl: img.ManAndBabyStudio_inLogo,
				},
				{
					title: 'Cash Value',
					description:
						'Some policies, like universal life insurance, offer a cash value component that grows over time and can be used during your lifetime.',
					imageUrl: img.FriendsCouch_inLogo,
				},
			];
			break;
		case 12:
			paragraph =
				"With umbrella insurance, you can have peace of mind knowing you have an extra layer of protection in case of unexpected events. It's an essential safeguard for your financial well-being and assets.";
			listData = [
				{
					title: 'Liability Protection',
					description:
						'Umbrella insurance provides additional liability coverage, protecting your assets and future earnings from lawsuits arising from accidents, injuries, or property damage for which you are responsible.',
					imageUrl: img.FamilyDancing_inLogo,
				},
				{
					title: 'Legal Expenses',
					description:
						"It covers legal fees and court costs if you're faced with a lawsuit, ensuring you have the resources to defend yourself in court.",
					imageUrl: img.Laptop_working,
				},
				{
					title: 'Property Damage',
					description:
						"If you or a covered family member accidentally damage someone else's property, your umbrella policy can cover the costs of repairs or replacements.",
					imageUrl: img.DogandHouse_inLogo,
				},
				{
					title: 'Injury Damages',
					description:
						'Umbrella insurance helps cover medical expenses and damages resulting from injuries sustained by others on your property or due to your actions.',
					imageUrl: img.ChildRunning,
				},
				{
					title: 'Libel and Slander',
					description:
						'It provides protection in case you are sued for libel, slander, or defamation, which can be especially important for businesses and individuals in the public eye.',
					imageUrl: img.Letter_writing,
				},
			];
			break;

		case 15:
			paragraph =
				"Our goal is to help you find the perfect pet insurance policy that suits your pet's needs and your budget. We'll compare various insurance providers to ensure you get the best coverage at a price you're comfortable with.";
			listData = [
				{
					title: 'Illness coverage',
					description:
						'This covers the cost of veterinary care and treatments if your pet becomes ill due to covered conditions.',
					imageUrl: img.DogandHouse_inLogo,
				},
				{
					title: 'Accident coverage',
					description:
						'This helps with expenses for emergency veterinary care in case of accidents or injuries.',
					imageUrl: img.ChildPlayingWithDog,
				},
				{
					title: 'Preventive care options',
					description:
						'Some plans offer coverage for routine check-ups, vaccinations, and preventative treatments.',
					imageUrl: img.CatInChair,
				},
				{
					title: 'Prescription medications',
					description:
						"Covers the cost of prescribed medications to treat your pet's health issues",
					imageUrl: img.Doctor_checking_dog,
				},
			];
			break;

		// Add more cases for other insurance types as needed

		default:
			listData = [];
			paragraph = '';
			break;
	}
	return { paragraph, listData };
};

