import { Agency } from "@bw/external-apis/shared";

interface AgencyAddressProps {
  agency: Agency;
  oneAddressLine?: boolean
}

export const AgencyAddress = ({ agency, oneAddressLine = false }: AgencyAddressProps) => {
  if (agency.corporateHideAddress){
    return <></>;
  }
  return (
    <address>
      <p>
      {agency.address1}
      { oneAddressLine ? null : <br /> }
      {agency.address2 ? <>
        {agency.address2}
        <br />
      </> : null}
      {agency.city}, {agency.stateAbbreviation} {agency.postalCode}
    </p>
  </address>
  )
}
