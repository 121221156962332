'use client';
import { Image } from '@bw/elements';
import { AgencyDetails } from '@bw/external-apis/shared';
import { agencyhomethree } from '../../../../stock-images';
import { useAppContext } from '../../../form-stepper/StepperProvider';
import { ShortForm } from '../../short-form/ShortForm';
import styles from './SAQImageColTextForm.module.scss';

export interface SAQImageColTextFormProps {
	agencyDetails: AgencyDetails;
}
type AnnouncementHeightProps = Record<string, string>;

export function SAQImageColTextForm({
	agencyDetails,
}: SAQImageColTextFormProps) {
	const { announcementBannerHeight } = useAppContext();
	const announcementHeight: AnnouncementHeightProps = {
		'--announcement-banner-height': `${announcementBannerHeight}px`,
	};
	return (
		<section className={styles.imageColTextFormSection}>
			<div className="container container--max">
				<div
					className={`${styles.imageColTextForm} ${styles['imageColTextForm--imageSmallDesign']}`}
					style={announcementHeight}
				>
					<article className={styles.textSection}>
						<h1 data-aos="fade-up">Start a quote</h1>
						<p className={styles.description} data-aos="fade-up">
							Ready to get started? Once you share your information here, a
							local expert will reach out to learn more about your unique needs
							and come back with multiple curated quotes from different
							companies.
						</p>
						<h5 className={styles.formTagline} data-aos="fade-up">
							Let&apos;s talk.
						</h5>
						<ShortForm agencyDetails={agencyDetails} />
					</article>
					<figure className={styles.imageSection} data-aos="fade-up">
						<Image
							alt="agency home hero image"
							src={agencyhomethree}
							width={1000}
							height={1000}
						/>
					</figure>
				</div>
			</div>
		</section>
	);
}
