import { logger } from '@bw/core';
import { AgencyDetails } from '@bw/external-apis/shared';

export const startsWithThe = (marketingName: string) => (/^\s*The\s/.test(marketingName));

export const withoutThe = (marketingName: string) => marketingName.replace(/^\s*The\s/, '');

type ContentfulEmployee = {
  firstName: string;
  lastName: string;
  legacyId?: number;
  headshotUrl?: string;
}

type LegacyEmployee = AgencyDetails['employees'][number];

export interface TeamMember {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  description: string;
  photoUrl: string;
  name: string;
  isFeatured: boolean;
}

export function getTeamMembers(
  legacyTeamMembers: AgencyDetails['employees'],
  contentfulTeamMembers: ContentfulEmployee[],
  sortMethod: 'Legacy' | 'Contentful'// = 'Legacy'
): TeamMember[] {
  if (sortMethod === 'Contentful') {
    legacyTeamMembers = contentfulTeamMembers.map(contentful => 
      legacyTeamMembers.find(legacy => teamMembersAreEqual(legacy, contentful))!
    ).filter(Boolean);
  }

  return legacyTeamMembers.map((employee) => {
    const contentfulEmployee = contentfulTeamMembers.find(emp => teamMembersAreEqual(employee, emp));
    if (!contentfulEmployee) {
      logger.error(`Contentful employee not found for employee ${JSON.stringify(employee)}, contentfulEmployees: ${JSON.stringify(contentfulTeamMembers)}`);
    }

    return {
      name: `${employee.firstName} ${employee.lastName}`,
      firstName: contentfulEmployee?.firstName ?? employee.firstName,
      lastName: contentfulEmployee?.lastName ?? employee.lastName,
      title: employee.title,
      email: employee.email,
      description: employee.description,
      photoUrl: contentfulEmployee?.headshotUrl ?? employee.photoFileName ?? '',
      isFeatured: employee.isFeatured ?? false,
    };
  });
}

const looselyEqual = (str1: string | undefined, str2: string | undefined) => {
  return str1?.trim()?.toLowerCase() === str2?.trim()?.toLowerCase();
}

export const teamMembersAreEqual = (legacyEmployee: LegacyEmployee, contentfulEmployee: ContentfulEmployee) =>
  legacyEmployee.id === contentfulEmployee.legacyId || (
    looselyEqual(legacyEmployee.firstName, contentfulEmployee.firstName) &&
    looselyEqual(legacyEmployee.lastName, contentfulEmployee.lastName)
  )
