import { PlayBtn } from '@bw/elements/icons';
import React from 'react';
import styles from './functionalityButton.module.scss';

interface FunctionalityButtonProps {
	title: string;
	textColor: string;
	backgroundColor: string;
	borderColor: string;
	containsPlayIcon?: boolean;
	onClick?: () => void;
}

const colorMapping: Record<string, string> = {
	black: ' #000',
	brightYellow: '#f0ff00',
	cream: '#ffffe6',
	deepBlue: ' #003049',
	periwinkleGrey: ' #8291AC',
	white: '#fff',
	// Add more color key-value pairs as needed
};

type ButtonStyle = Record<string, string>;

const FunctionalityButton: React.FC<FunctionalityButtonProps> = ({
	title,
	textColor,
	backgroundColor,
	borderColor,
	containsPlayIcon,
	onClick,
}) => {
	const buttonStyle: ButtonStyle = {
		'--bg-color':
			backgroundColor === 'none'
				? '#003049'
				: colorMapping[backgroundColor] || backgroundColor,
		'--text-color': colorMapping[textColor] || textColor,
		'--border-color': colorMapping[borderColor] || 'transparent',
	};
	return (
		<>
			<button
				className={styles.functionalityButton}
				style={buttonStyle}
				type="button"
				onClick={onClick}
				data-aos="fade-up"
			>
				{containsPlayIcon && (
					<>
						<PlayBtn width={25} height={25} className="svg is-icon-right" />
					</>
				)}
				{title}
			</button>
		</>
	);
};

export default FunctionalityButton;
