'use client';

import {
	GoogleMap,
	InfoWindow,
	Marker,
	useJsApiLoader,
} from '@react-google-maps/api';
import Link from 'next/link';
import { useState } from 'react';
import { MAP_PIN_URL } from '../../constants';
import styles from './MapComponent.module.scss';

interface MapComponentProps {
	agencyDetails: {
		id: number;
		marketingName: string;
		address1: string;
		address2: string;
		city: string;
		stateAbbreviation: string;
		postalCode: string;
		geoLocationLatitude: number;
		geoLocationLongitude: number;
	};
	apiKey?: string;
}

export function MapComponent({ agencyDetails, apiKey }: MapComponentProps) {
	const center = {
		lat: agencyDetails.geoLocationLatitude ?? 37.09024,
		lng: agencyDetails.geoLocationLongitude ?? -95.712891,
	};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: apiKey ?? '',
	});

	const [mapActive, setMapActive] = useState(false);

	return isLoaded ? (
		<div className={styles.mapWrapper}>
			<GoogleMap
				mapContainerClassName={styles.mapContainer}
				center={center}
				zoom={10}
				clickableIcons={false}
			>
				<Marker
					key={agencyDetails.id}
					position={center}
					icon={mapActive ? MAP_PIN_URL.ACTIVE : MAP_PIN_URL.NORMAL}
					onClick={() => setMapActive(true)}
					label={{
						className: styles.markerLabelText,
						text: 'Map Pin'
					}}
				>
					{mapActive ? (
						<InfoWindow
							onCloseClick={() => setMapActive(false)}
							position={{
								lat: +agencyDetails.geoLocationLatitude,
								lng: +agencyDetails.geoLocationLongitude,
							}}
						>
							<>
								<div className={styles.markerAgencyName}>
									{agencyDetails.marketingName}
								</div>
								<div className={styles.markerAgencyAddress}>
									{agencyDetails.address1} {agencyDetails.address2}
									<br />
									{agencyDetails.city},&nbsp;
									{agencyDetails.stateAbbreviation} {agencyDetails.postalCode}
								</div>
								<div className={`${styles.markerLink}`}>
									<Link
										href={`https://www.google.com/maps?q=${agencyDetails.geoLocationLatitude},${agencyDetails.geoLocationLongitude}`}
										title={`Google map link to ${agencyDetails.marketingName}`}
										target="_blank"
										aria-label={`Google map link to ${agencyDetails.marketingName} (opens in a new tab)`}
									>
										View on Google Maps
									</Link>
								</div>
							</>
						</InfoWindow>
					) : null}
				</Marker>
			</GoogleMap>
		</div>
	) : (
		<></>
	);
}
