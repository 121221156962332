'use client';
import { LoggerEvents, logger } from '@bw/core';
import { Image } from '@bw/elements';
import { LoaderBlue } from '@bw/elements/icons';
import { AgencyReferralFormData } from '@bw/external-apis';
import { AgencyDetails } from '@bw/external-apis/shared';
import { ChangeEvent, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FORM_ERROR } from '../../../constants';
import { ApiContext } from '../../../server';
import { FamilyOnPorch_with_Logo } from '../../../stock-images';
import FormButton from '../../utility/formButton/formButton';
import { InsuranceTypeSelect } from '../insurance-type-select/InsuranceTypeSelect';
import { StateIdSelect } from '../state-id-select/StateIdSelect';
import styles from './AgencyReferralForm.module.scss';

export type RequestState =
	| {
			status: 'ready' | 'loading' | 'success';
	  }
	| {
			status: 'error';
			error: Error;
	  };

export interface AgencyReferralFormProps {
	agencyDetails: AgencyDetails;
	submitAgencyReferralForm: ApiContext['agencyReferral']['submitAgencyReferralForm'];
}

export function AgencyReferralForm({ agencyDetails, submitAgencyReferralForm }: AgencyReferralFormProps) {
	const [requestState, setRequestState] = useState<RequestState>({
		status: 'ready',
	});
	const [hideForm, setHideForm] = useState<boolean>(false);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const getCaptchaToken = async (action: string | undefined): Promise<string> => {
    const message = 'executeRecaptcha not available on form submit, possible react script loading error.';
    if (!executeRecaptcha) {
      logger.error(LoggerEvents.GeneralErrorWebApp, { message });
      return 'no recaptcha';
    }
    try {
      const token = await executeRecaptcha(action);
      return token;
    } catch (error) {
      logger.error(LoggerEvents.GeneralErrorWebApp, { message });
    }
    return 'no recaptcha';
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<AgencyReferralFormData>();
	const onSubmit: SubmitHandler<AgencyReferralFormData> = async (data) => {
		setRequestState({ status: 'loading' });
		setHideForm(true);
		try {
			const response = await submitAgencyReferralForm(agencyDetails.agency.storeNumber)(
				{
					...data,
					stateId: Number(data.stateId),
					leadInsuranceTypeId: Number(data.leadInsuranceTypeId),
					captchaToken: await getCaptchaToken('agencyReferralForm'),
				}
			);
			if (typeof response.error === 'string') {
				throw new Error(response.error);
			}
			setRequestState({ status: 'success' });
			reset();
		} catch (error) {
			if (error instanceof Error) {
				setRequestState({ status: 'error', error });
				setHideForm(false);
			}
		}
	};
	const ThanksDisplay = ({ state }: { state: RequestState }) => {
		return (
			<>
				{state.status === 'success' && (
					<div className={styles.success}>
						<h5 className="headingTagline">Submission Successful!</h5>
						<h2>Thank you!</h2>
						<p>Check your email. We’ll be in touch soon!</p>
					</div>
				)}
			</>
		);
	};
	const ErrorDisplay = ({ state }: { state: RequestState }) => {
		return (
			<>
				{state.status === 'error' && (
					<div className={styles.error}>{state.error.message}</div>
				)}
			</>
		);
	};
	const LoadingDisplay = ({ state }: { state: RequestState }) => {
		return (
			<>
				{state.status === 'loading' && (
					<div className="is__pageloader">
						<figure>
							<LoaderBlue width={100} height={100} />
						</figure>
					</div>
				)}
			</>
		);
	};
	const clearErrorState = () => {
		if (requestState.status === 'error') {
			setRequestState({ status: 'ready' });
		}
	};

	const ConditionalFieldError = (props: { fieldName: string }) => {
		return (
			<span>
				{errors[props.fieldName] ? errors[props.fieldName].message : ''}
			</span>
		);
	};

	const handleOnInput = (e: ChangeEvent<HTMLInputElement>) => {
		e.target.value = e.target.value.replace(/[^0-9]/g, '')
	}

	return (
		<section className={styles.AgencyReferralSection}>
			<div className="container container--max">
				<div className={styles.ReferralForm}>
					<article>
						{!hideForm ? (
							<>
								<h3 data-aos="fade-up">
									Let Brightway, {agencyDetails.agency.marketingName} be your
									referral partner.
								</h3>
								<h4 data-aos="fade-up">It&apos;s a win-win-win.</h4>
								<form
									id="agency-page-referral-form"
									className={styles.formSection}
									data-aos="fade-up"
									onSubmit={handleSubmit(onSubmit)}
									onChange={clearErrorState}
								>
									<fieldset>
										<div className={styles.fieldContainer}>
											<div className={styles.splitField}>
												<label htmlFor="referredByFirstName" data-aos="fade-up">
													Referrer First Name
												</label>
												<input
													id="referredByFirstName"
													type="text"
													{...register('referredByFirstName', {
														required: FORM_ERROR.REQUIRED,
														pattern: {
															value: /^(?!^\s+$)[\w\s'-]{1,70}$/,
															message: FORM_ERROR.FNAME,
														},
													})}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="referredByFirstName" />
											</div>
											<div className={styles.splitField}>
												<label htmlFor="referredByLastName" data-aos="fade-up">
													Referrer Last Name
												</label>
												<input
													id="referredByLastName"
													type="text"
													{...register('referredByLastName', {
														required: FORM_ERROR.REQUIRED,
														pattern: {
															value: /^(?!^\s+$)[\w\s'-]{1,70}$/,
															message: FORM_ERROR.LNAME,
														},
													})}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="referredByLastName" />
											</div>
										</div>
										<label htmlFor="referredByEmail" data-aos="fade-up">
											Referrer Email
										</label>
										<input
											id="referredByEmail"
											type="text"
											{...register('referredByEmail', {
												required: FORM_ERROR.REQUIRED,
												pattern: {
													value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
													message: FORM_ERROR.EMAIL,
												},
											})}
											data-aos="fade-up"
										/>
										<ConditionalFieldError fieldName="referredByEmail" />
										<div className={styles.fieldContainer}>
											<div className={styles.splitField}>
												<label htmlFor="firstName" data-aos="fade-up">
													Customer First Name
												</label>
												<input
													id="firstName"
													type="text"
													{...register('firstName', {
														required: FORM_ERROR.REQUIRED,
														pattern: {
															value: /^(?!^\s+$)[\w\s'-]{1,70}$/,
															message: FORM_ERROR.FNAME,
														},
													})}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="firstName" />
											</div>

											<div className={styles.splitField}>
												<label htmlFor="lastName" data-aos="fade-up">
													Customer Last Name
												</label>
												<input
													id="lastName"
													type="text"
													{...register('lastName', {
														required: FORM_ERROR.REQUIRED,
														pattern: {
															value: /^(?!^\s+$)[\w\s'-]{1,70}$/,
															message: FORM_ERROR.LNAME,
														},
													})}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="lastName" />
											</div>
										</div>
										<div className={styles.fieldContainer}>
											<div className={styles.splitField}>
												<label htmlFor="email" data-aos="fade-up">
													Customer Email
												</label>
												<input
													id="email"
													type="text"
													{...register('email', {
														required: FORM_ERROR.REQUIRED,
														pattern: {
															value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
															message: FORM_ERROR.EMAIL,
														},
													})}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="email" />
											</div>
											<div className={styles.splitField}>
												<label htmlFor="phone" data-aos="fade-up">
													Customer Phone Number
												</label>
												<input
													id="phone"
													type="text"
													{...register('phone', {
														required: FORM_ERROR.REQUIRED,
														pattern: {
															value:
																/^([0-9]{3})[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/,
															message: FORM_ERROR.PHONENUMBER,
														},
													})}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="phone" />
											</div>
										</div>
										<div className={styles.fieldContainer}>
											<div className={styles.splitField}>
												<label htmlFor="address1" data-aos="fade-up">
													Insured Property Address
												</label>
												<input
													id="address1"
													type="text"
													{...register('address1', {
														required: FORM_ERROR.REQUIRED,
														pattern: {
															value: /^(?!\s*$).*/,
															message: FORM_ERROR.REQUIRED,
														},
													})}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="address1" />
											</div>
											<div className={styles.splitField}>
												<label htmlFor="address2" data-aos="fade-up">
													Apartment or Suite #
												</label>
												<input
													id="address2"
													type="text"
													{...register('address2')}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="address2" />
											</div>
										</div>
										<div className={styles.fieldContainer}>
											<div className={styles.splitField}>
												<label htmlFor="city" data-aos="fade-up">
													City
												</label>
												<input
													id="city"
													type="text"
													{...register('city', {
														required: FORM_ERROR.REQUIRED,
														pattern: {
															value: /^(?!\s*$).*/,
															message: 'Enter valid city',
														},
													})}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="city" />
											</div>
											<div className={styles.splitField}>
												<label htmlFor="postalCode" data-aos="fade-up">
													ZIP Code
												</label>
												<input
													id="postalCode"
													type="text"
													inputMode='numeric'
													minLength={5}
													maxLength={5}
													onInput={handleOnInput}
													{...register('postalCode', {
														required: FORM_ERROR.REQUIRED,
														pattern: {
															value: /^(?!00000)\d{5}$/,
															message: 'Enter valid ZIP Code',
														},
													})}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="postalCode" />
											</div>
										</div>
										<div className={styles.fieldContainer}>
											<div className={styles.splitField}>
												<StateIdSelect
													{...register('stateId')}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="stateId" />
											</div>
											<div className={styles.splitField}>
												<InsuranceTypeSelect
													insuranceTypes={
														agencyDetails.agency.agencyInsuranceTypes
													}
													{...register('leadInsuranceTypeId')}
													data-aos="fade-up"
												/>
												<ConditionalFieldError fieldName="leadInsuranceTypeId" />
											</div>
										</div>
										<label htmlFor="origin" data-aos="fade-up">
											How did you hear about us?
										</label>
										<textarea
											id="origin"
											{...register('origin')}
											data-aos="fade-up"
										/>
										<ConditionalFieldError fieldName="origin" />

										<FormButton
											title="Send"
											containsSubmitIcon={false}
											type="submit"
										/>
										<ErrorDisplay state={requestState} />
									</fieldset>
								</form>
							</>
						) : (
							<LoadingDisplay state={requestState} />
						)}
						<ThanksDisplay state={requestState} />
					</article>
					<figure className={styles.imageSection} data-aos="fade-up">
						<Image
							src={FamilyOnPorch_with_Logo}
							alt=""
							width={2000}
							height={2000}
						/>
					</figure>
				</div>
			</div>
		</section>
	);
}
