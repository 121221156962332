import { AgencyInsuranceType } from "@bw/external-apis/shared";
import { Ref, forwardRef } from "react";

interface InsuranceTypeSelectProps {
  [key: string]: unknown;
  insuranceTypes: AgencyInsuranceType[];
}

export const InsuranceTypeSelect = forwardRef(
  ({ insuranceTypes, ...ctrlProps }: InsuranceTypeSelectProps, ref: Ref<HTMLSelectElement>) =>
  <>
    <label htmlFor="dropdown" data-aos="fade-up">I&apos;m interested in...</label>
    <select id="dropdown" name='insuranceTypeId' ref={ref} {...ctrlProps} required data-aos="fade-up">
      <option value="" disabled>
        Insurance Type
      </option>
      {
        insuranceTypes
          .sort(byInsuranceTypeSortOrder)
          .map(type =>
            <option key={type.insuranceTypeId} value={type.insuranceTypeId}>
              {type.insuranceTypeName}
            </option>
          )
      }
    </select>
  </>
)

const byInsuranceTypeSortOrder = (a: AgencyInsuranceType, b: AgencyInsuranceType) => {
  return a.insuranceTypeSortOrder - b.insuranceTypeSortOrder;
};
