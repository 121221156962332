'use client';
import styles from './AgencyTeamTextOnlySlider.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

/* eslint-disable-next-line */
export interface AgencyTeamTextOnlySliderProps {}

export function AgencyTeamTextOnlySlider(props: AgencyTeamTextOnlySliderProps) {
	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		arrows: false,
	};
	return (
		<div className={styles.onlyTextContainer} data-aos="fade-up">
			<ul>
				<Slider {...settings}>
					<li>
						<p aria-hidden="true">Your future-you will thank you.</p>
					</li>
					<li>
						<p aria-hidden="true">Choice is always a good decision.</p>
					</li>
					<li>
						<p aria-hidden="true">Insurance your way.</p>
					</li>
					<li>
						<p aria-hidden="true">We&apos;re bullish about your future.</p>
					</li>
					<li>
						<p aria-hidden="true">More carriers. More choice.</p>
					</li>
					<li>
						<p aria-hidden="true">We don&apos;t just know insurance. We know your community.</p>
					</li>
					<li>
						<p aria-hidden="true">Get insurance the right way. Not the cross-your-fingers way.</p>
					</li>
				</Slider>
			</ul>
		</div>
	);
}
