'use client';
import { useSuspenseQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { GET_TEXT_SLIDER_ITEMS } from '../../queries';
import styles from './TextSlider.module.scss';

interface TextContent {
	json: any;
}

interface TextSliderDataProps {
	textSliderCollection: {
		items: {
			name: string;
			componentName: string;
			sliderText: TextContent;
		};
	};
}

export interface TextSliderProps {
	name: string;
}

// TODO: get rid of that isClient nonsense and make this a server component

export const TextSlider: React.FC<TextSliderProps> = (props) => {
	const [isClient, setIsClient] = useState(false);
	const [activeSlide, setActiveSlide] = useState(0);

	useEffect(() => {
		setIsClient(true);
	}, []);
	const { data } = useSuspenseQuery<TextSliderDataProps>(
		GET_TEXT_SLIDER_ITEMS,
		{
			variables: {
				component: props.name,
			},
		}
	);
	const cflSliderTextInput = data.textSliderCollection.items[0].sliderText.json;

	const sliderText = useMemo(() => {
		const temp: string[] = [];

		const searchThroughCFLNodes = (node) => {
			if (node.content) {
				node.content.forEach((subNode) => {
					searchThroughCFLNodes(subNode);
				});
			}
			if ('value' in node && node.value) {
				temp.push(node.value);
			}
		};
		searchThroughCFLNodes(cflSliderTextInput);

		return temp;
	}, [cflSliderTextInput]);

	const settings = {
		infinite: true,
		speed: 1500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		arrows: false,
	};

	const handleSetActiveSlide = (index: number) => {
		setActiveSlide(index);
	};
	return (
		<section className={styles.textSliderSection}>
			<div className="container container--max">
				{isClient && sliderText.length > 0 ? (
					<div className={styles.textSlider} data-aos="fade-up">
						<div className={styles.sliderTrack}>
							<Slider afterChange={handleSetActiveSlide} {...settings}>
								{sliderText.map((text, idx) => (
									<div key={`${text}-${idx}`}>
										<p aria-hidden={activeSlide === idx ? false : true}>
											{text}
										</p>
									</div>
								))}
							</Slider>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
		</section>
	);
};

export default TextSlider;
